<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Station Standing Data</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Station Standing Data widget provides you with static details about a station within the NEM, along with basic generation details about the units within the station for the current dispatch interval. This widget is useful for you to quickly ascertain general information about the station and it's operations. </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/stationstandingdata-image1.png" alt="Gen Change Widget">
                <br/>
                <h2 class="gr-h2">Description</h2>
                <p>This widget displays static information about each station and it's containing units. The main purpose of this widget is to help you look up specific information about a station and it's units such as fuel type, physical location, capacity, participant details,  etc.</p>
                <br/>
                <p>The left side of the widget displays details for the station as a whole, while the right side allows you to look up details for any of the contained units within the station. </p>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/stationstandingdata-image2.png" alt="Gen Change Widget">
                <br/>
                <h2 class="gr-h2">Station ID</h2>
                <br/>
                <img class="w-1/6 rounded-m mx-auto" src="@/assets/stationstandingdata-image3.png" alt="Gen Change Widget">
                <br/>
                <p>This represents the ID for station as it is registered with the AEMO. You can click on the small search button next to the station Id to change the station that data is being displayed for in the widget.</p>
                <h2 class="gr-h2">Station Details</h2>
                <p>This section of the widget displays registration details for the station as a whole. Here you will be able to find general information about the physical plant and the participant to whom the station is registered to. </p>
                <h2 class="gr-h2">List of Units</h2>
                <p>In this table you will see all generating units that are registered within the station. For each unit the table displays generation data for the current dispatch interval. Clicking on a row within this table will bring up further details in the 'Unit Details' panel below. </p>
                <h2 class="gr-h2">Unit Details</h2>
                <br/>
                <img class="w-1/3 rounded-m mx-auto" src="@/assets/stationstandingdata-image4.png" alt="Gen Change Widget">
                <br/>
                <p>The Unit Details panel displays further information about the individual unit that is selected in the table above. </p>
                <br/>
                <p>In the top-right corner of this panel you will see basic generation figures for the unit in the current dispatch interval. </p>
                <br/>
                <p>Below, you will find physical specifications and other general information about the unit such as it's maximum capacity, maximum rate of change, connection point, voltage level, etc. </p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Station Standing Data'
};
</script>