<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mb-2 mx-auto">
            <router-link to="/design-principles" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Design Principles</p></router-link>
        </div>
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
                <router-link to="/design-principles/quickaccessbuttons" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <!-- Heroicon name: arrow-narrow-left -->
                    <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                    <p>Previous: <b> Quick Access Buttons</b></p>
                </router-link>
            </div>
            <div class="hidden md:-mt-px md:flex">
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
                <router-link to="/design-principles/privatedata" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <p>Next: <b> Adding Private Data</b></p>
                    <!-- Heroicon name: arrow-narrow-right -->
                    <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </router-link>
            </div>
        </nav>   
        <div class="text-lg mx-auto mb-6">
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Sharing Preferences</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">ez2view opens new opportunities for collaboration within your organisation by enabling user settings such as layouts, filters and notifications to be stored in the cloud. </p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <p>A convenient migration tool, available when ez2view is initially launched, allows users with existing settings stored locally to migrate them to the cloud.  </p>
            <br/>
            <p>Settings can be shared among colleagues meaning layouts or tailored filters and even alert settings can be conveniently used by anyone with the right permissions in your organisation.  </p>
            <h2 class="gr-h2">Cloud Settings</h2>
            <p>The cloud storage location for user settings is enabled by selecting the 'Cloud' option in the ez2view’s User Settings Menu found at Tools > Options > User Settings, as pictured below.</p>
            <br/>
            <img class="w-full lg:w-1/2 rounded-m mx-auto" src="@/assets/sharingpreferences-image1.png" alt="Cloud Settings">
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Sharing with colleagues</h3>
            <p>Cloud-stored settings are only accessible to the individual by default, as indicated by the Private label of the sharing link. </p>
            <br/>
            <img class="w-1/2 lg:w-1/6 rounded-m mx-auto" src="@/assets/sharingpreferences-image2.png" alt="Private Link">
            <br/>
            <p>Sharing can be configured by clicking the link when configuring each setting.</p>
            <br/>
            <p>In the layout configuration example below, the sharing link can be found in the bottom-left of the window.</p>
            <br/>
            <img class="w-full lg:w-1/2 rounded-m mx-auto" src="@/assets/sharingpreferences-image3.png" alt="Edit Application Layout Window">
            <br/>
            <p>When sharing settings, users have the following two options:</p>
            <ol class="pl-8 pt-2 pb-2 list-decimal">
              <li>'Add User' gives the option to select a specific user for sharing.</li>
              <li>'Add Everyone' give the option to share with all users in the organisation.  </li>
            </ol>
            <br/>
            <img class="w-full lg:w-1/4 rounded-m mx-auto" src="@/assets/sharingpreferences-image4.png" alt="Sharing Editor Window">
            <br/>
            <p>The sharing permissions can be configured when the User Permissions window opens after clicking 'Add User' or 'Add Everyone':</p>
            <ol class="pl-8 pt-2 pb-2 list-disc">
              <li>'Can Only Read' permits the selected user to use the setting in their ez2view session.</li>
              <li>'Can Edit' permits the selected user to use and make changes to the setting.</li>
            </ol>
            <br/>
            <img class="w-full lg:w-1/5 rounded-m mx-auto" src="@/assets/sharingpreferences-image5.png" alt="User Permissions Window">
            <br/>
            <p>After adding or updating a setting it may take up to 2 mins for this to appear in other users’ applications.</p>
            <br/>
            <p>Users seeking to make changes to a setting that is “Can only Read” will initially see a message indicating insufficient permissions. Users should duplicate the setting so that a copy is saved with Sharing and other setting parameters configured as needed.</p>
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Shared setting conflicts when saving</h3>
            <p>In rare situations save instructions for a single shared setting may be issued from different users at the same time. When this happens the first save instruction is accepted. The second save instruction is not permitted (as it would overwrite the first) and instead results in a duplication of the setting for the second user. The duplicated setting may then be modified, shared, or deleted as needed.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Sharing of notification rules</h3>
            <p>Shared notification rules will be enabled for all users if set as Enabled. To avoid releasing active notification rules to colleagues we recommend sharing notification rules in Disabled mode.  Any users seeking to enable the notification rule should then make a new rule that matches, but is not shared, and is set to Enabled. </p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Working offline</h3>
            <p>If your internet drops out you can keep using ez2view with Cloud settings as they are cached in memory and temporarily backed-up locally on the computer. </p>
            <br/>
            <p>While offline, you can add and edit new settings. Existing settings that are already in the Cloud are not editable to avoid save conflicts when the internet connection resumes. Instead, your edits to existing settings will be saved by duplicating the setting. </p>
            <br/>
            <p>Once the internet connection resumes, all settings will be refreshed into the Cloud. You can close ez2view while the internet is off, and the settings won’t be lost.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Migrate existing settings</h3>
            <p>ez2view comes with a migration tool that will copy your settings (if any) to the cloud to save you time.</p>
            <br/>
            <p>When ez2view first runs the system will check whether you are currently set to use the Local File System and, if so, will provide the option to switch to using the Cloud location. As this location will have no setting saved initially, you may also migrate your existing settings to the new location.</p>
            <br/>
            <img class="w-full lg:w-1/2 rounded-m mx-auto" src="@/assets/sharingpreferences-image6.png" alt="Migration Tool">
            <br/>
            <p>Users wanting to change at a later date may click  Retain local file system as setting storage location. </p>
            <br/>
            <p>At other times during ez2view usage, the migration tool can be found in the options menu Tools > Options > User Settings, as pictured below.</p>
            <br/>
            <img class="w-full lg:w-1/2 rounded-m mx-auto" src="@/assets/sharingpreferences-image7.png" alt="Migrate Existing Settings">
            <br/>
            <p>Users seeking to migrate their settings from this window may use the Setting Migration functionality at the bottom of the window. </p>
            <br/>
            <p>Once the ‘from’ and the ‘to’ sources are set click Migrate… and follow the prompts in the next pop up window communicating what will be migrated, how to deal with duplicates, and results of the migration process when completed.  </p>
            <br/>
            <p>The migration functionality also supports transfer from a shared settings database to the cloud for users who have this configured. We recommend asking Global-Roam for guidance when migrating from a shared settings database.</p>
            <br/>
            <p>Settings may be identified as duplicates by the migration tool when migrating to the cloud if they have the same setting ID. </p>
            <br/>
            <p>When this happens, the user will be given the option to skip or migrate the setting. If migrate is selected the setting will be duplicated with the user’s name prepended to the setting name.</p>
            <br/>
            <p>Duplicate settings can be expected during a migration when:</p>
            <ol class="pl-8 pt-2 pb-2 list-decimal">
              <li>Migrating from a shared settings database where multiple users have access to the same setting.</li>
              <li>Migrating a setting that was copied manually as a file from another person’s session.</li>
            </ol>
            <br/>
            <p>Strategies for dealing with numerous duplicate entries include:</p>
            <ol class="pl-8 pt-2 pb-2 list-disc">
              <li>Nominating a single user to perform the migration and then, as necessary, re-share settings using <i>Share With Everyone</i>.</li>
              <li>Don’t migrate duplicates.</li>
              <li>Migrate duplicates and resolve sharing collaboratively with colleagues.</li>
            </ol>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Alternate storage locations</h3>
            <p>Existing setting storage locations are still supported:</p>
            <ol class="pl-8 pt-2 pb-2 list-disc">
              <li>The local file system (the default location prior to version 8) can still be used to store user settings. This location does not support sharing functionality. </li>
              <li>The shared database storage location, an SQL database hosted and managed by your organisation, is still supported to cater for existing users who have this configured. This location provides a smaller subset of sharing functionality than the Cloud option. For example, cloud settings can be shared with support@global-roam.com to facilitate support inquiries, cloud settings are cached on the hard disk supporting working while offline, and the text box is replaced by a drop down list when selecting specific users for sharing a cloud setting.</li>
            </ol>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Sharing Preferences'
};
</script>