<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg mx-auto mb-6">
        <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
        <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Gas Data</h1>
        <p class="text-xl text-gray-500 leading-8 font-semibold">
          The Gas Data widget provides a real-time overview of the price and quantities of natural gas as it is scheduled to be traded in the Australian east coast gas markets each day; the Short Term
          Trading Market (STMM) and the Victorian Declared Wholesale Gas Market (DWGM).
        </p>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
          <br />
          <img class="w-1/2 rounded-m mx-auto" src="@/assets/gasdata-image1.png" alt="Gas Data Widget" />
          <br />
          <h2 class="gr-h2">Description</h2>
          <p>
            The STTM is a wholesale market for the trading of natural gas at the wholesale level at the Brisbane, Sydney and Adelaide hubs. The AEMO issues day-ahead market schedules for price and
            quantity and each hub is scheduled and settled separately.
          </p>
          <br />
          <p>
            The DWGM provides a market-based balancing service for the whole of Victoria that determines price and gas quantities at intervals of 4 hours during the day, with a larger 8-hour interval
            applying overnight.
          </p>
          <br />
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Ex-Ante Price</h3>
          <br />
          <img class="rounded-m mx-auto" src="@/assets/gasdata-image2.png" alt="Gas Data Ex-Ante" />
          <br />
          <p>
            For each of the hubs described above, the widget displays the most recently published ex-ante gas price. In this widget, colour is used to represent magnitude of change, as we describe in our
            <router-link to="/design-principles/inbuiltalerts" class="text-blue-600 hover:underline">in-built alerts page</router-link>, from the last published price.
          </p>
          <br />
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Utilisation</h3>
          <br />
          <img class="rounded-m mx-auto" src="@/assets/gasdata-image3.png" alt="Gas Data Utilisation" />
          <br />
          <p>The utilisation data in the map represents the current physical gas flow for each pipeline in relation to its maximum physical capacity.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: 'Gas Data'
};
</script>
