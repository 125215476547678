<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg mx-auto mb-6">
        <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
        <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Regional Commodity Summary Trend</h1>
        <p class="text-xl text-gray-500 leading-8 font-semibold">The regional commodity summary trend presents a timeseries of demand, availability and price for the relevant commodity and region.</p>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
          <br />
          <img class="w-2/3 rounded-m mx-auto" src="@/assets/regionalcommoditysummarytrend-image1.png" alt="Regional commodity summary trend (energy)" />
          <br />
          <h2 class="gr-h2">Description</h2>
          <p>Each chart shows region-specific availability (light grey area), demand (grey area) and price (lines).</p>
          <ul class="pl-8 pt-2 pb-2">
            <li>
              • <b>Availability</b> : The total available capacity to supply the commodity (in MW) summed for all scheduled and semi-scheduled units in the region as declared by each generator (as
              generated).
            </li>
            <li>• <b>Demand</b> : The demand of the commodity, for the region (in MW), as met by scheduled and semi-scheduled units.</li>
            <li>
              • <b>Price</b> : The Regional Reference Price (RRP) for the commodity, shown as the solid black line series, represents the five or thirty minute pool price for the Region. Additionally, for
              energy, predispatch price sensitivity forecasts are also charted using dashed lines. Typically, these are the +/-100 MW and +/- 200 MW scenarios and provide an indication of the
              sensitivity of the energy price to the demand.
            </li>
          </ul>

          <br />
          <p>
            The current point in time is indicated by a vertical line. Dispatch interval data is displayed for historical periods and points within the 5-minute predispatch forecast timeframe.
            Predispatch 30-minute forecasts allow the chart series to extend to the start of the next NEM trading day.
          </p>
          <br />
          <!-- <img class="w-2/3 rounded-m mx-auto" src="@/assets/regionalcommoditysummarytrend-image1.png" alt="Zoomed in regional commodity summary trend (energy)" />
          <br /> -->
          <p>If you hover your mouse cursor over the chart a pop-up appears showing the numeric values for the time period under the cursor.
              Many other features of <router-link to="./preconfigured-trend" class="inline text-blue-600 hover:underline">preconfigured trends</router-link>, also apply.</p>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: 'Regional Commodity Summary Trend'
};
</script>
