<template>
    <div id="app" class="bg-image">
        <header-section />
        <div class="flex overflow-hidden w-3/4 mx-auto">
            <sidebar-menu />
            <content-area>
                <router-view />
            </content-area>
        </div>
        <footer-section />
    </div>
</template>

<script>
import HeaderSection from '@/components/Header.vue';
import SidebarMenu from '@/components/SidebarMenu.vue';
import ContentArea from '@/components/ContentArea.vue';
import FooterSection from '@/components/Footer.vue';

export default {
    name: 'App',
    components: {
        HeaderSection,
        SidebarMenu,
        ContentArea,
        FooterSection
    }
};
</script>

<style>
body {
    margin: 0;
    padding: 0;
}

.prose .max-w-prose .prose-lg {
    max-width: 100ch;
}

#app {
    padding: 0;
    margin: 0;
}

.text-grblack {
    color:  #403b41;
}

li:hover .hover-text-grblue {
    color:  #5273aa;
}

a:hover .hover-link-grblue {
    color:  #5273aa;
}

.text-grblue {
    color:  #5273aa;
}

.text-grred {
    color:  #E53E45;
}

.text-grgreen {
    color:  #7DAD13;
}

.text-gryellow {
    color:  #DDB400;
}

.textheader-grblue:hover {
    color:  #5273aa;
}

.textheader-grred:hover {
    color:  #E53E45;
}

.textfooter-grred:hover {
    color:  #E53E45;
}

.textheader-grgreen:hover {
    color:  #7DAD13;
}

.textheader-gryellow:hover {
    color:  #DDB400;
}

.bg-grblack {
    background-color: #403b41;
}

.bg-grblack-300 {
  background-color: rgba(64, 59, 65, 0.4);
}

.hover-bg-grblue:hover {
    background-color:  #5273aa;
}

.border-grblack {
    --border-opacity: 1;
    border-color: #403b41;
}

.bg-image {
    background-image: url("assets/ez2view-background.png");
    margin: 0;
    padding: 0;
}

.hover-hide {
    display: none; 
}

li:hover .hover-show{
    display: none; 
}

li:hover .hover-hide{
     display: inline;
}

.bg-grblack-900 {
  background-color: rgba(64, 59, 65, 0.9);
}

.text-grblack {
  color: #403b41;
}

.bg-grgrey {
  background-color: #f0f0f0;
}

.focus-bg-grblue:focus {
  background-color: #5273aa;
}

.focus-text-grblack:focus {
  color: #403b41;
}

.focus-font-semibold:focus {
  font-weight: 600;
}

.hover-text-grblack:hover {
  color: #403b41;
}

.list-lower-roman {
list-style-type: lower-roman;
}

.list-lower-alpha {
list-style-type: lower-alpha;
}

/* Tab content - closed */
.tab-content {
max-height: 0;
-webkit-transition: max-height .35s;
-o-transition: max-height .35s;
transition: max-height .35s;
}
/* :checked - resize to full height */
.tab input:checked ~ .tab-content {
max-height: 200vh;
}
/* Label formatting when open */
.tab input:checked + label{
/*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
font-size: 1.25rem; /*.text-xl*/
padding: 1.25rem; /*.p-5*/
border-left-width: 2px; /*.border-l-2*/
border-color: #403b41; /*.border-indigo*/
background-color: #d1d5db; /*.bg-gray-300 */
color: #403b41; /*.text-indigo*/
}
/* Icon */
.tab label::after {
float:right;
right: 0;
top: 0;
display: block;
width: 1.5em;
height: 1.5em;
line-height: 1.5;
font-size: 1.25rem;
text-align: center;
-webkit-transition: all .35s;
-o-transition: all .35s;
transition: all .35s;
}
/* Icon formatting - closed */
.tab input[type=checkbox] + label::after {
content: "+";
font-weight:bold; /*.font-bold*/
border-width: 1px; /*.border*/
border-radius: 9999px; /*.rounded-full */
border-color: #b8c2cc; /*.border-grey*/
}
.tab input[type=radio] + label::after {
content: "\25BE";
font-weight:bold; /*.font-bold*/
border-width: 1px; /*.border*/
border-radius: 9999px; /*.rounded-full */
border-color: #b8c2cc; /*.border-grey*/
}
/* Icon formatting - open */
.tab input[type=checkbox]:checked + label::after {
transform: rotate(315deg);
background-color: #e53e45; 
color: #f8fafc; /*.text-grey-lightest*/
}
.tab input[type=radio]:checked + label::after {
transform: rotateX(180deg);
background-color: #e53e45; 
color: #f8fafc; /*.text-grey-lightest*/
}

/* you can use Tailwind’s @apply directive to extract repeated utility patterns to custom CSS classes when a template partial feels heavy-handed. */
@layer components {
    .gr-text-link {
        @apply inline text-blue-600 hover:underline
    }

    .gr-list-disc {
        @apply pl-12 pt-2 pb-2 list-disc
    }

    .gr-h2 {
        @apply mt-8 mb-4 text-4xl font-bold tracking-tight text-grblack lg:leading-8;
        
    }
    /* ... */
}

</style>
