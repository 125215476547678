<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6 pb-6 border-b-1">
            <router-link to="/complex-market-concepts" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Complex Market Concepts</p></router-link>
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Unit Dispatch</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">Lorem ipsum.</p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <h2 class="gr-h2">Dispatch in the NEM</h2>
            <p>Dispatch is the<p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">How dispatch works in the NEM</h3>
            <p>Dispatch is the </p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">National Electricity Market Dispatch Engine (NEMDE)</h3>
            <p>NEMDE is the central software program that computes dispatch instructions. </p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Conformance</h3>
            <p>Lorem ipsum.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Further Reading on Dispatch</h3>
            <p>Over on WattClarity, we have been working to provide detailed explainers and analysis pieces on bidding and price setting in the NEM. Below you will find links to some of our covergage on the topic:
                <ol class="pl-15 pt-2 pb-2">
                    <li>• <b><a href="http://www.wattclarity.com.au/articles/2018/08/beginners-guide-to-how-dispatch-works-in-the-nem-and-hence-how-prices-are-set/" class="text-grblue hover:underline">Beginner’s Guide to how dispatch works in the NEM, and hence how prices are set </a></b> by <b>Paul McArdle</b> </li>
                </ol>
            </p>
            <br/>
            <h2 class="mt-16 mb-6 text-4xl leading-4 font-bold tracking-tight text-grblack sm:text-4xl sm:leading-8">Analysing Dispatch in ez2view</h2>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Bids & Offers Widget</h3>
            <br/>
            <img class="mx-auto w-1/3" src="@/assets/constraints-image1.png" alt="Bound Constraints Widget">
            <br/>
            <p>Lorem ipsum.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Price Setter Data Widget</h3>
            <br/>
            <img class="mx-auto w-1/2" src="@/assets/constraints-image2.png" alt="Constraint Equations Widget">
            <br/>
            <p>Lorem ipsum. </p>
            <br/>
            <br/>
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">References</h3>
            <ol class="pl-15 pt-2 pb-2">
                <li><sup>1</sup> <a href="https://aemo.com.au/en/energy-systems/electricity/national-electricity-market-nem/system-operations/congestion-information-resource/constraint-faq" class="text-grblue hover:underline">Example Reference</a></li>
            </ol>
            

        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Unit Dispatch'
};
</script>