<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">MITC AER Report</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The MITC AER Report Generation widget helps transmission companies in reporting constraint equations associated with the AER's MITC incentive scheme. </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/mitcaerreport-image1.png" alt="Gen Change Widget">
                <br/>
                <h2 class="gr-h2">Description</h2>
                <p> The Australian Energy Regulator administers an incentive scheme to encourage transmission companies to manage and minimise the Market Impacts of Transmission Congestion (MITC). Under this program, each transmission company reports on bound constraints that the TNSP has been responsible for during the relevant reporting period. </p>
                <br/>
                <p> The MITC AER Report Generation widget enables you to easily export your MITC data to a CSV file for further analysis in a spreadsheet. 
                    The data is extracted from the MMS MCC_CONSTRAINTSOLUTION table and combined with any previously saved annotations (for date-time & constraint combinations) 
                    using the <router-link to="./mitc-analysis" class="inline text-blue-600 hover:underline"> MITC Analysis widget</router-link>. </p>
                <br/>
                <h2 class="gr-h2">Generating a report</h2>
                <p>This widget will generate the MITC report as a CSV file. To generate the report, you must:</p>
                <ol class="pl-8 pt-2 pb-2">
                    <li>1. Specify a date range.</li>
                    <li>2. (Optional) Create a filter to specify which type of constraints are included in the report.</li>
                    <li>3. Select which data fields you want to export. The full list is found below.</li>
                </ol>
                <div class="mx-auto flex flex-col">
                <div class="mx-auto">
                    <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                        <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="mx-auto divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                    <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                                        <p class="text-lg text-center font-bold">Field</p>
                                    </th>
                                    <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                                        <p class="text-lg text-center font-bold">Description</p>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Settlement Date
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The dispatch interval in which the constraint equation was bound. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Constraint ID
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The identifier of the constraint equation that bound. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Effective Date
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Effective date of the constraint equation. This field indicates the version of the constraint equation that was used in the dispatch interval. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Version Number
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The version number of the constraint equation. This field indicates the version of the constraint equation that was used in the dispatch interval. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Marginal Value
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The marginal value of the constraint in the MCC run for the dispatch interval. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Constraint Description
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Details of the plant that is not in service. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Network Condition
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Either "System Normal" or "Outage". 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Category
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Additional categorisation of the network constraint. When possible ez2view Australia will automatically set the initial category value based on the Constraint Equation ID. If an initial category value cannot be inferred from the Constraint Equation ID, ez2view will set the category to 'UNKNOWN'. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Outage Equipment
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        A field in which you can record the details of the equipment involved in the related outage. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Asset Class
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Identifies whether the equipment is either a regulated asset or non-regulated asset. If the outage is related to non-regulated assets, exclusion code 4 must be entered. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Code
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        A field in which you can select the AER exclusion and inclusion code for the constraint equation, if one applies. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Exclusion Detail
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        A field in which you can record any details on the exclusion claim (i.e. reference to supportive evidence, or outage details). 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Weighting
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        A field in which you can attribute the weighting for the outage. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        TNSP Comments
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        A field in which you can record any comments that you may have about this constraint equation. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        OMS ID
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        A field in which you can record your Outage Management System identifier for the outage related to this constraint equation. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Outage Type
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        A field in which you can select "Planned" or "Unplanned" as the outage type. The default value is blank. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Constraint Set ID
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The identifier of the constraint set that the bound constraint belongs to. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Impact
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The device(s) that is affected by the constraint e.g. Interconnector, Generator(s), etc. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Reason
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The contingency or reason for the constraint. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Limit Type
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The limit type of the constraint e.g. Transient Stability, Voltage Stability. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Region
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Derived from Constraint ID. AEMO use a naming convention when formulating the identifiers for constraint equations (the Constraint ID). The first characters of the naming convention identify the Region, or other special conditions, to which the constraint equation applies. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Source
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The source of the constraint formulation. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Additional Notes
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Extra notes on the constraint. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Constraint Type
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The logical operator which is either =, >= or &lt;=. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Generic Constraint Weight
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        The constraint violation penalty factor. 
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Modifications
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Details of the changes made to this version of the constraint. 
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
                <m-i-t-c-database />
            </div>
        </div>
    </div>
    </div>
</template>

<script>

import MITCDatabase from './MITCDatabase.vue';

export default {
  components: { MITCDatabase},
    title: 'MITC AER Report'
};
</script>