<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg mx-auto mb-6">
        <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
        <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">MITC Outage Report</h1>
        <p class="text-xl text-gray-500 leading-8 font-semibold">
          The MITC Outage Report Generation widget helps transmission companies find outages and generate reports to be submitted in line with the AER's MITC incentive scheme.
        </p>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
          <br />
          <img class="w-2/3 rounded-m mx-auto" src="@/assets/mitcoutagereport-image1.png" alt="Gen Change Widget" />
          <br />
          <h2 class="gr-h2">Description</h2>
          <p>
            The Australian Energy Regulator administers an incentive scheme to encourage transmission companies to manage and minimise the Market Impacts of Transmission Congestion (MITC). Under this
            program, each transmission company reports on outages that the TNSP has been responsible for during the relevant reporting period.
          </p>
          <br />
          <h2 class="gr-h2">Generating a report</h2>
          <p>This widget will generate the MITC report as a CSV file. To generate the report, you must:</p>
          <ol class="pl-15 pt-2 pb-2">
            <li>1. Specify a date range.</li>
            <li>2. Select which data fields you want to export. The full list is found below.</li>
          </ol>
          <div class="mx-auto flex flex-col">
            <div class="mx-auto">
              <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="mx-auto divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                          <p class="text-lg text-center font-bold">Field</p>
                        </th>
                        <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                          <p class="text-lg text-center font-bold">Description</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          OutageId
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          ID uniquely identifying the outage
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          SubstationId
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          Identifier for the substation the equipment is located at.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          EquipmentId
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          A unique identifier for the equipment at this substation, and based on its type.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          EquipmentType
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                           The type of equipment: LINE = Line; TRANS = Transformer; CB = Circuit breaker;
                                        ISOL = Isolator; CAP = Capacitor; REAC = Reactor; UNIT = Unit.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          StartTime
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          The planned starting date and time of the outage.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          EndTime
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          The planned ending date and time of the outage.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          OutageStatusCode
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          A code representing the status of the outage. The OUTAGESTATUSCODE table, described in the MMS Data Model Report, 
                           will store a detailed description of each code.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          Reason
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          The reason provided by the asset owner for this outage.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          IsSecondary
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          TRUE = The outage is for a secondary piece of equipment that has an associated constraint set. 
                          The transmission equipment is still in service. <br/> 
                          FALSE = The outage is for the transmission equipment.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          ActualStartTime
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          The actual starting date/time of the outage.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          ActualEndTime
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          The actual ending date/time of the outage.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          CompanyReferenceCode
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          The asset owners reference code for this outage.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          GenConSetId
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          Unique ID for the Constraint Set that the outage constraint belongs to.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          Voltage
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          The voltage in KV for the equipment. Transformers may have multiple voltages defined. E.g. 132_110_33
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          EquipmentDescription
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          A short description for the equipment (i.e name of equipment).
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          SubstationDescription
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          A short description for the substation (i.e name of substation).
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          RegionId
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          NEM region identifier.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          
          <m-i-t-c-database />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: 'MITC Outage Report'
};
</script>
