<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Regional Summary Stats</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Regional Summary Stats widget provides an overview of the current state of availability, generation and demand for a given region of the NEM.  </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-1/3 rounded-m mx-auto" src="@/assets/regionalsummarystats-image1.png" alt="Regional Summary Stats Widget">
                <br/>
                <h2 class="gr-h2">Description</h2>
                <p>The NEM Summary Stats shows displays a table and chart of availability, demand and generation totals across the NEM as a whole, as well as time-series charts of aggregated metered generation and emissions rate by region.</p>
                <br/>
                <h2 class="gr-h2">Region Supply/Demand Balance Table</h2>
                <p>The summary table shows the following totals for the current dispatch interval:</p>
                <ul class="pl-8 pt-2 pb-2">
                    <li>• <b>Regional Availability</b> - Total Availability for the region</li>
                    <li>• <b>Regional Demand</b> - Total Metered Demand for the region</li>
                    <li>• <b>Generation Surplus</b> - Regional Availability minus Regional Demand</li>
                    <li>• <b>Net Import</b> - Aggregate Flow of Interconnectors to/from the Region</li>
                    <li>• <b>Regional Surplus</b> - Generation Surplus plus Net Import</li>
                </ul>
                <h2 class="gr-h2">Region Zonal Generation Chart</h2>
                <p>The Zonal Generation Chart is a time-series line graph of generation in each of the zones (as defined by the local TNSP) in the region. The x-axis of the chart shows the past 6 hours before the current dispatch interval.</p>
                <br/>
                <p>Hovering over the chart with your mouse will display a tooltip that shows the specific values for each zone in the region, along with the dispatch interval where your mouse is positioned on the chart. </p>
                <br/>
                <img class="w-1/4 rounded-m mx-auto" src="@/assets/regionalsummarystats-image2.png" alt="Regional Summary Stats Widget">
                <br/>
                <h2 class="gr-h2">Region Zonal Demand Chart</h2>
                <p>Where the data is available to you (via subscription to TNSP data), this chart will display a time-series line graph of demand in each TNSP zone within the region. 
                    This chart also shows data looking back over the past 6 hour period.</p>
                <br/>
                <p>As explained above, you can also hover over this chart to see the exact figures for each zone at a given point in time. </p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Regional Summary Stats'
};
</script>