<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg mx-auto mb-6">
        <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
        <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">SMS Alarms</h1>
        <p class="text-xl text-gray-500 leading-8 font-semibold">
          The SMS Alarms Widget allows you to configure alerts to be sent to one or more mobile phone numbers when triggered, so that you can stay on top of when market conditions affect your organisation.
        </p>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
          <br />
          <br />
          <img class="w-1/3 rounded-m mx-auto" src="@/assets/customalerts-image3.png" alt="Received SMS" />
          <br />
          <h2 class="gr-h2">Description</h2>
          <p>
            With this widget you can configure rules based on market conditions or events - such as dispatch price, regional demand, market notices, etc. - that when triggered, send a message to one
            or more mobile phone numbers that you specify.
          </p>
          <br />
          <p>Initially, the main 'alarms' page will be displayed providing the following functionality.</p>
          <ul class="pl-15 pt-2 pb-2">
            <li>
              • Access alarm settings by either clicking on an existing alarm or the new alarm button:
              <div class="inline-block" vertical-align="bottom"><img class=" rounded-sm" src="@/assets/smsalarmsnewalarm-image0.png" alt="new alarm button" /></div>
            </li>
            <li>
              • Expand/collapse the sidebar by clicking the 'hamburger':
              <div class="inline-block" vertical-align="bottom"><img class=" rounded-sm" src="@/assets/hamburger-image0.png" alt="hamburger" /></div>
            </li>
            <li>
              • SMS Credits are displayed:
              <div class="inline-block" vertical-align="bottom"><img class=" rounded-sm" src="@/assets/smsalarmscredits-image0.png" alt="credits" /></div>
              <br />
              One credit is used each time an SMS is sent. Contact Global-Roam if your credits are running out.
            </li>
            <li>
              • The light theme can be toggled to a dark theme using slide toggle:
              <div class="inline-block" vertical-align="bottom"><img class=" rounded-sm" src="@/assets/smsalarmstheme-image0.png" alt="theme toggle" /></div>
            </li>
          </ul>
          <br />
          <img class="w-1/2 rounded-m mx-auto" src="@/assets/smsalarms-image0.png" alt="SMS Alarms Widget" />
          <br />

          <h2 class="gr-h2">Setting up an alert</h2>
          <p>Setting up an alarm is as simple as clicking <b>New Alarm</b> however it is useful to first create some contacts to receive the alerts.</p>
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Creating a new contact</h3>
          <br />
          <img class="w-1/3 rounded-m mx-auto" src="@/assets/smsalarms-image2.png" alt="Address Book" />
          <br />
          <p>
            Before setting up an alarm to trigger a SMS message you will need to create at least one contact who will receive the SMS messages. To do this, click on the 'Address Book' link in the
            sidebar. This page will allow you to create a new contact(s) to receive the SMS alerts. You will need to specify a contact name and mobile in the format of +61 4######## or 04######## to
            add the contact.
          </p>
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Creating a new alarm</h3>
          <br />
          <img class="w-1/2 rounded-m mx-auto" src="@/assets/smsalarms-image4.png" alt="SMS Alarms Widget" />
          <br />
          After you've created the contacts that you wish to attach to alerts, you can navigate back to the 'Alarms' page in the top navigation bar to configure the alarm.
          <ul class="pl-8 pt-2 pb-2">
            <li>
              1. <b>Designate a name for the alarm</b>
              <ul class="pl-8 pb-2">
                <li>A unique name to identify and label the alarm you are configuring.</li>
              </ul>
            </li>
            <li>
              2. <b>Define the rule/condition to trigger the alarm</b>
              <ul class="pl-8 pb-2">
                <li>
                  The specific condition that needs to be triggered for the SMS alarm to be sent. Alerts can be configured based on thresholds for the following data points:
                  <ul class="pl-8 pb-2">
                    <li>‣ Market Notices</li>
                    <li>‣ Disptach Interconnector Constraint Export or Import</li>
                    <li>‣ Disptach Interconnector Flow</li>
                    <li>‣ Dispatch Instantaneous Reserve Plant Margin</li>
                    <li>‣ Dispatch Demand</li>
                    <li>‣ Dispatch Price</li>
                    <li>‣ Predispatch Price</li>
                    <li>‣ Trading Interconnector Flow</li>
                    <li>‣ Trading Instantaneous Reserve Plant Margin</li>
                    <li>‣ Trading Demand</li>
                    <li>‣ Trading Price</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              3. <b>Specify the message content to send when triggered </b>
              <ul class="pl-15 pb-2">
                <li>
                  Here you specify the message that will be sent when the condition is triggered. Use the [data] and [date] tags to customise the message by automatic substitution of actual values.
                </li>
              </ul>
            </li>
            <li>
              4. <b>Specify the contact to receive the SMS alarm </b>
              <ul class="pl-15 pb-2">
                <li>
                  Here you will tick the contacts that you wish to receive the SMS alarm. You will first need to create a new contact using the instruction above to add a new contact to your address
                  book.
                </li>
              </ul>
            </li>
            <li>
              5. <b>Set the name of the sender field of the SMS</b>
              <ul class="pl-15 pb-2">
                <li>Here you enter the name that will be displayed in the SMS as the sender's name.</li>
              </ul>
            </li>
          </ul>
          <br />
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Flexible access</h3>
          <br />
          <p>
            The widget is accessible to licensed users through a regular web browser at <a href="https://notifications.global-roam.com" class="text-grblue hover:underline">https://notifications.global-roam.com</a>. 
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: 'SMS Alarms'
};
</script>
