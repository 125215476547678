<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Constraint Dashboard</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Constraint Dashboard widget visualises the values and factors associated with the Left Hand Side (LHS) of a constraint equation. It is focused on helping the user interpret the LHS terms to understand how individual units contribute to the binding of the constraint. </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="rounded-m mx-auto" src="@/assets/constraintdashboard-overview-N-NIL-X5-BEKG.png" alt="Constraint Dashboard">
                <br/>
                <h2 class="gr-h2">Description</h2>
                <p>The widget presents a chart on the left and a table on the right. The chart presents the LHS term-contributions of the selected constraint equation for each interval in the time range. The table presents a more detailed view of each LHS term for the specific interval selected. </p>
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Toolbar</h3>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/constraintdashboard-toolbar.png" alt="Constraint Dashboard Toolbar">
                <br/>
                <p>Use this to select the constraint and the time range for the chart. Click on the magnifying glass icon, and type in the constraint ID to select which constraint you want to display data for. Here you can also set the lookback and look forward periods for the chart. </p>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Charts</h3>
                <p>Two time-series charts are stacked on the left side of the widget.</p>
                <br/>
                <img class="w-4/5 rounded-m mx-auto" src="@/assets/constraintdashboard-main-chart.png" alt="Constraint Dashboard Top Left Chart">
                <br/>
                <p>The chart at the top is a stacked area graph which shows the MW contribution (LHS Factor multiplied by Target) for each LHS term. These are colour-coded to what's shown in the table on the right side of the widget. Terms that have a negative contribution are also displayed. </p>
                <br/>
                <p>The dotted black line in the chart represents the RHS value while the solid black line represents the the sum of the LHS contributions (the LHS total). </p>
                <br/>
                <img class="w-4/5 rounded-m mx-auto" src="@/assets/constraintdashboard-mv-chart.png" alt="Constraint Dashboard Bottom Left Chart">
                <br/>
                <p>The bottom chart consists of two sub-charts which support identification of periods when the constraint is binding and the magnitude of its impact (represented by the marginal value): </p>
                <ol class="gr-list-disc">
                    <li><b>Bound (MV)</b> is filled when the constraint was <a href="https://wattclarity.com.au/articles/2020/11/casestudy-x5-constraint/">bound</a> or is forecast to bind, given the current point in time. </li>
                    <li><b>Marginal value</b> is filled to the level of the marginal value of the constraint. </li>
                </ol>
                <br/>
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Table</h3>
                <br/>
                <img class="w-4/5 rounded-m mx-auto" src="@/assets/constraintdashboard-table.png" alt="Constraint Dashboard Table">
                <br/>
                <p>The table on the right side of the widget displays further information about the LHS terms of the equation. The columns in this table represent:</p>
                <br/>

                <table class="w-4/5 mx-auto">
                  <thead>
                  <tr>
                    <th class="p-6">Term</th>
                    <th class="p-6">Description</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="bg-gray-200">
                    <td class="p-4"><b>Id</b></td>
                    <td class="p-4">The ID of the unit (DUID) or interconnector, or region. Terms in the equation that do not map to
                      a known entity may appear differently -- for example, an underlying connection point ID.
                    </td>
                  </tr>
                  <tr class="bg-white">
                    <td class="p-4"><b>Factor</b></td>
                    <td class="p-4">The LHS factor of the term. Shading towards the right of the cell represents the size of the
                      factor in the positive direction, shading to the left of center represents the relative size of a
                      negative factor.<br/>
                      In ez2view generally, for consistency, we have inverted all pure load unit targets to be negative.
                      For this reason, the "factor" for pure load units is also inverted in this widget, to properly
                      represent the contribution in the correct direction as per the formulation of the constraint.
                      A red triangle indicator in the corner of the cell reminds that the factor has been inverted for
                      pure loads.
                    </td>
                  </tr>
                  <tr class="bg-gray-200">
                    <td class="p-4"><b>Target/Available</b></td>
                    <td class="p-4">The number in the center of the cell is the Target, or Estimated Target if in real time.<br/>
                      Shading is relative to the maximum capacity in both load (left) and gen (right) directions.<br/>
                      Grey shading represents the directional target. A red/pink portion represents the
                        remaining available capacity. Crosshatched shading represents availability in the "other"
                        not-currently-dispatched direction, for bidirectional units.
                    </td>
                  </tr>
                  <tr>
                    <td class="p-4"><b>Contribution</b></td>
                    <td class="p-4">The LHS factor multiplied by the Target in MW. Again, directional shading represents this value
                      relative to the largest contributor's contribution.
                    </td>
                  </tr>
                  <tr class="bg-gray-200">
                    <td class="p-4"><b>CPD Price</b></td>
                    <td class="p-4">The Connection Point Dispatch Price of the unit. Faded (grey) text represents that the CPD price
                      was the same as the RRP.
                    </td>
                  </tr>
                  <tr>
                    <td class="p-4"><b>RRP</b></td>
                    <td class="p-4">The Regional Reference Price for the unit's region.</td>
                  </tr>
                  <tr class="bg-gray-200">
                    <td class="p-4"><b>Rebid</b></td>
                    <td class="p-4">The dark icon indicates that the bid had materially changed (could affect the outcome of NEMDE)
                      since the prior interval. The faded icon indicates that a new bid for the unit was submitted, with
                      immaterial differences. No icon indicates the dispatched bid has not changed since last interval
                      (or there was no bid).
                    </td>
                  </tr>
                  <tr>
                    <td class="p-4"><b>Next MW (L)</b></td>
                    <td class="p-4">The bid band (price) that the next MW <em>on the load side</em> would be dispatched from.</td>
                  </tr>
                  <tr class="bg-gray-200">
                    <td class="p-4"><b>Last MW @</b></td>
                    <td class="p-4">The bid band (price) at which the last MW was dispatched. Directional shading represents the
                      dispatched volume of that bid band relative to the volume offered. Pink shading represents
                      additional availability at that bid band that was not dispatched.
                    </td>
                  </tr>
                  <tr>
                    <td class="p-4"><b>Next MW (G)</b></td>
                    <td class="p-4">The bid band (price) that the next MW <em>on the generation side</em> would be dispatched from.</td>
                  </tr>
                  <tr class="bg-gray-200">
                    <td class="p-4"><b>Dispatch Type</b></td>
                    <td class="p-4">Whether the unit is a Generator, Load, or BDU.</td>
                  </tr>
                  <tr>
                    <td class="p-4"><b>Market</b></td>
                    <td class="p-4">The Market this term applies to in the constraint.</td>
                  </tr>
                  </tbody>

                </table>

                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Table Interactivity</h3>
                <br/>
                <p>Rows on the table may be mouse-clicked to select them. When selected, that unit's contributions are highlighted in the chart. </p>
                <p>Multiple rows can be selected with ctrl-click or shift-click. Deselect rows with the same action.</p>
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Data Availability</h3>
                <br/>
                <p>The 5-minute predispatch (P5MIN) forecast data at the unit-level is NOT public next day, but is eventually made public some months later. This is the period between (but excluding) the current interval and 60 minutes into the future. When not available, unit-level data in the chart and table may be absent. In this situation only regional and interconnector constraint contributions will be able to be displayed.</p>
                <br/>
                <p>The 30-minute predispatch (P30) forecast data at the unit-level is public next day and will display when in time-travel for the forecast horizon beyond the P5 window.</p>
                <br/>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Constraint Dashboard'
};
</script>