<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg mx-auto mb-6">
        <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
        <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Large Scale Intermittent Generation Trend</h1>
        <p class="text-xl text-gray-500 leading-8 font-semibold">
          There is one of these trend widgets per region, and they have been designed to provide some visibility of the current AEMO forecasts for how much generation is forecast to be provided by all the Intermittent Generation Capacity in the region. The trend incorporates actual data with P30 and ST PASA forecasts.
        </p>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
          <br />
          <img class="w-2/3 rounded-m mx-auto" src="@/assets/largescaleintermittentgenerationtrend.png" alt="Image of the Region Trend Large Solar Intermittent Generation" />
          <br />
          <h2 class="gr-h2">Description</h2>
          <p>The trend displays 24 hours history and a sliding forecast into the future using "P30" predispatch data and ST PASA data.</p>
          <br />
          <p>Some key points to note about this trend:</p>
          <ul class="pl-8 pt-2 pb-2">
            <li>
              • The data sets included in the trends mean that the numbers will reflect more than just wind and large solar (i.e. with the inclusion of other intermittent sources), however the wind
              and solar productions are expected to dominate in most cases.
            </li>
            <li>• The forecasts exclude “behind-the-meter” smaller scale rooftop PV projections.</li>
            <li>• The forecasts are for unconstrained generation (so don’t take into account any curtailment due to transmission constraints).</li>
          </ul>
          <br />
          <div class="mx-auto flex flex-col">
            <div class="mx-auto">
              <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table>
                      <tbody class="mx-auto divide-y divide-gray-200">
                        <tr>
                          <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                            <p class="text-lg text-center font-bold">Segment of time on the Chart</p>
                            <p />
                          </th>
                          <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                            <p class="text-lg text-center font-bold">Database Table</p>
                            <p />
                          </th>
                          <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                            <p class="text-lg text-center font-bold">Database Field</p>
                            <p />
                          </th>
                        </tr>

                        <tr>
                          <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Historical to "Now"</td>
                          <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">DISPATCHREGIONSUM</td>
                          <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">UIGF + TOTALINTERMITTENTGENERATION</td>
                        </tr>
                        <tr>
                          <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Predispatch time-range</td>
                          <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">PREDISPATCHREGIONSUM</td>
                          <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">UIGF + TOTALINTERMITTENTGENERATION</td>
                        </tr>
                        <tr>
                          <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">ST PASA time range</td>
                          <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">STPASA</td>
                          <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">LOR_SEMISCHEDULEDCAPACITY + TOTALINTERMITTENTGENERATION</td>
                        </tr>
                      </tbody>
                    
                  </table>
                </div>
              </div>
            </div>
          </div>
          <br />
          <p />
          <p>Linkages to other widgets:</p>
          <ul class="pl-8 pt-2 pb-2">
            <li>• This trend chart is powered by Trends (which is the same underlying technology that is used to support ez2view customers with the Trend Editor).</li>
            <li>• These same calculations are used in the “Forecast Convergence” Widget, in which you can view how successive forecasts have changed over time.</li>
          </ul>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: 'Large Scale Intermittent Generation Trend'
};
</script>
