<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mb-2 mx-auto">
            <router-link to="/getting-started" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Getting Started</p></router-link>
        </div>
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
                <router-link to="/getting-started/datadelivery" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                <!-- Heroicon name: arrow-narrow-left -->
                <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                </svg>
                <p>Previous: <b> Data Delivery</b></p>
                </router-link>
            </div>
            <div class="hidden md:-mt-px md:flex">
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
                <router-link to="/getting-started/ez2viewinstaller" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-md sm:text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                <p>Next: <b> ez2view Installer</b></p>
                <!-- Heroicon name: arrow-narrow-right -->
                <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
                </router-link>
            </div>
        </nav>  
        <div class="text-lg mx-auto mb-6 pb-6 border-b-1">
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Activation</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">Each licence to ez2view applies organisation-wide. Once your organisation's licence has been confirmed, each individual user will need to register a Global-Roam account to use ez2view. This account will then allow you to activate and log-in to both ez2view Desktop and ez2view Online.</p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <h2 class="gr-h2">ez2view Desktop</h2>
            <p>You will first need to download and install the <router-link to="/getting-started/ez2viewInstaller" class="text-blue-600 hover:underline">desktop software installer file</router-link>. From ez2view v8.0 onwards, each individual user will need to register and log in using their own Global-Roam account. </p>
            <br/>
            <p>After you install and run the software for the first time, a pop-up window will appear, like shown below, prompting you to register a new account or log-in.</p>
            <br/>
            <div class="w-full lg:w-1/3 mx-auto screenshot">
                <img class="mx-auto" src="@/assets/loggingin-image2.png" alt="Logging In">
                <img class="watermark" src="@/assets/loggingin-image3.png" alt="Logging In - Screenshot Only">
            </div>
            <br/>
            <p>When registering your account, you must provide the email address at the domain of the organisation that owns the licence. For example, if ez2view is licenced to your organisation 'Company XYZ', then you must use your @companyxyz.com email address. </p>
            <br/>
            <p>You can <router-link to="/contact-us" class="text-blue-600 hover:underline">contact us</router-link> if you encounter any difficulties with registering or logging in.</p>
            <h2 class="gr-h2">ez2view Online</h2>
            <p>You can access different components of ez2view here:</p>
            <br/>
            <a href="https://app.ez2view.info" class="text-blue-600 hover:underline"><h2 class="text-3xl text-center font-extrabold sm:text-3xl">app.ez2view.info</h2></a>
            <br/>
            <p>You can use the same Global Roam account that you use for the desktop software. If you need you can <a href="https://authentication.global-roam.com/registration" class="text-blue-600 hover:underline">register your own account online</a> using your work email address. </p>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Activation'
};
</script>

<style scoped>
    .screenshot {
        width: 450px;
        height: auto;
        position: relative;
        margin: auto;
    }
    .screenshot .watermark {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
    }
    .screenshot:hover .watermark {
        display: inline;
    }
</style>