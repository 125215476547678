<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mb-2 mx-auto">
            <router-link to="/getting-started" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Getting Started</p></router-link>
        </div>
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
                <router-link to="/getting-started/accesspoints" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                <!-- Heroicon name: arrow-narrow-left -->
                <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                </svg>
                <p>Previous: <b> Access Points</b></p>
                </router-link>
            </div>
            <div class="hidden md:-mt-px md:flex">
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
                <router-link to="/getting-started/activation" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                <p>Next: <b> Activation</b></p>
                <!-- Heroicon name: arrow-narrow-right -->
                <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
                </router-link>
            </div>
        </nav>    
        <div class="text-lg mx-auto mb-6 border-b-1">
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Data Delivery</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The bulk of essential market dispatch and trading data accessible in ez2view is published by the AEMO through their electricity Market Management System (MMS). Market participants who are registered as generators with the AEMO are provided an exclusive connection to their own operating data through the AEMO’s MMS Data Model. We allow our clients to configure the data delivery in ez2view to the method that best suits their needs. </p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <h2 class="gr-h2">ez2view Desktop</h2>
            <h3 id="choose" class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Choosing a Data Delivery Method</h3>
            <p>We provide multiple delivery methods for you to receive AEMO MMS data in your desktop copy of ez2view. You can use the flow chart below to decide which method best suits your needs.</p>
            <br/>
            <img class="w-full lg:w-1/2 mx-auto" src="@/assets/datadelivery-image4.png" alt="Data Delivery Methods Flow Chart">
            <br/>
            <h4 class="mt-10 mb-2 text-lg font-bold tracking-tight text-grblack sm:text-lg"><span class="text-grblue">Method 1A</span>: Private MMS</h4>
            <p>If you are a registered market participant with access to your own private AEMO data, we recommend that you configure ez2view to be pointed at your own MMS so that you have greater visibility of your organisation's assets within the software.</p>
            <br/>
            <p>Configuring ez2view through this method will allow you to view and access your own private operating data within the software including: </p>
            <ol class="pl-10 pt-2 pb-2 list-disc">
                <li>Dispatch targets and predispatch targets for your units</li>
                <li>Your bids for the current day and next day</li>
                <li>Your unit availability, including forecast availability from AWEFS or ASEFS</li>
                <li>Any private constraints that only pertain to your unit, so are not visible in real time.</li>
            </ol>
            <br/>
            <p>Your MMS must be in one of the two database platforms that the AEMO officially supports: Microsoft SQL Server or Oracle.</p>
            <h4 class="mt-12 mb-2 text-lg font-bold tracking-tight text-grblack sm:text-lg"><span class="text-grblue">Method 1B</span>: Managed Private MMS</h4>
            <p>We have several clients who wish to access their own private AEMO data within the software (like in Method 1A) but have requested that we set-up and manage this database for them. In these cases, we have set up a replication of their MMS through a dedicated Azure cloud data server in which only they can access.</p>
            <br/>
            <p>Please <router-link to="/contact-us" class="text-blue-600 hover:underline">contact us</router-link> directly if you would like to request this method of data delivery.</p>
            <h4 class="mt-12 mb-2 text-lg font-bold tracking-tight text-grblack sm:text-lg"><span class="text-grred">Method 2A</span>: Global-Roam Cloud Data Source</h4>
            <p>When you first install ez2view, this will be the <b>default method</b> of how ez2view receives AEMO data. </p>
            <br/>
            <p>To cater for non-market participants and others, we centrally manage a common cloud data source that includes essential AEMO MMS data that is available to all market participants, but it does not include any private data that is exclusive to generators.</p>
            <h4 class="mt-12 mb-2 text-lg font-bold tracking-tight text-grblack sm:text-lg"><span class="text-grred">Method 2B</span>: Managed Public MMS</h4>
            <p>We have several clients that are non-market participants who have requested for us to set-up and manage a MMS on a dedicated cloud sever. Some of these clients have wished to configure ez2view in this way so that they can access the MMS outside of ez2view and perform their own SQL queries directly. </p>
            <br/>
            <p>Please <router-link to="/contact-us" class="text-blue-600 hover:underline">contact us</router-link> directly if you would like to request this method of data delivery.</p>
            <h3 class="mt-12 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Data Delivery in ez2view Desktop</h3>
            <p>The map below shows how AEMO MMS data and other data is delivered to your copy of ez2view based on the method above in which you have chosen.</p>
            <br/>
            <img class="w-full lg:w-2/3 mx-auto" src="@/assets/datadelivery-image5.png" alt="ez2view Desktop Data Delivery Map">
            <br/>
            <p>Regardless of the data delivery method you have chosen, non-MMS data (i.e. data that is not sourced from the AEMO) is delivered to you via separate cloud databases. We also use separate secure cloud databases to store shared user settings and other preferences.</p>
            <h2 class="gr-h2">ez2view Online</h2>
            <p>Currently our web widgets are not designed to receive private MMS data, hence all widgets in the online app will receive data via our common cloud MMS and other cloud databases.</p>
            <br/>
            <img class="w-full lg:w-1/3 mx-auto" src="@/assets/datadelivery-image6.png" alt="ez2view Online Data DeliveryMap">
            <br/>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Data Delivery'
};
</script>