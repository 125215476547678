<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mb-2 mx-auto">
            <router-link to="/getting-started" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Getting Started</p></router-link>
        </div>
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
            </div>
            <div class="hidden md:-mt-px md:flex">
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
                <router-link to="/getting-started/datadelivery" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                <p>Next: <b> Data Delivery</b></p>
                <!-- Heroicon name: arrow-narrow-right -->
                <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
                </router-link>
            </div>
        </nav>
        <div class="text-xl mx-auto mb-6 pb-6 border-b-1">
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Access Points</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">
            <p class="text-xl text-gray-500 leading-8 font-semibold"> Most of the value that ez2view delivers comes through the installable desktop software and hence the majority of the information on this site relates to using <b>ez2view Desktop</b> as it is the primary access point.
            <br/><br/>More recently we've been working to build and expand <b>ez2view Online</b>, a secondary means for users to access some of ez2view's functionality through a web browser.</p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <img class="w-full lg:w-2/3 mx-auto" src="@/assets/accesspoints-image5.png" alt="Installed and Web Widgets">
            <br/>
            <p>The data acquisition, analysis and visualisation functionality of the software is broken up into many underlying <i><b>'widgets'</b></i>. We’ve written a more <router-link to="/design-principles/builddashboard" class="text-blue-600 hover:underline">detailed explanation about widgets on this site</router-link>, but for simplicity sake you can think of widgets as ‘apps inside the app’ - they are tools and displays that allow you to analyse and/or visually process different types of electricity market data. Each one focuses on a specific aspect of the market such as constraints, bidding, unit dispatch, etc. 
            <br/>
            <br/>
            <p>In terms of accessibility, widgets fall into two categories:</p>
            <ol class="pl-12 pt-2 pb-2 list-decimal">
                <li><b>Installed Widgets</b> – installed locally, accessing data and content through either your local MMS or our cloud MMS; and </li>
                <li><b>Web Widgets</b> - sourced from the web, accessing data and content through our cloud MMS. </li>
            </ol>
            <h2 class="gr-h2">ez2view Desktop</h2>
            <br/>
            <img class="w-full mx-auto lg:w-1/2" src="@/assets/accesspoints-image3.png" alt="ez2view Desktop">
            <br/>
            <p>The desktop software allows you to access our full suite of widgets - both installed and web. We've also established numerous <router-link to="/design-principles" class="text-blue-600 hover:underline">design principles</router-link> that apply across the desktop software, including functionality to help you build your own dashboard, navigate through historical data, configure alerts, etc. </p>
            <br/>
            <p>You can <router-link to="/getting-started/ez2viewinstaller" class="text-blue-600 hover:underline">download the install file from our installer page</router-link>. Once installed, you'll need to <router-link to="/getting-started/activation" class="text-blue-600 hover:underline">sign in with your GR-id and password</router-link> to activate your licence.</p>
            <h2 class="gr-h2">ez2view Online</h2>
            <br/>
            <img class="w-full mx-auto lg:w-1/2" src="@/assets/accesspoints-image4.png" alt="ez2view Online">
            <br/>
            <p>Our online app can be viewed through any standard web browser and allows you access to a small but growing subset of web-based widgets. You can access the web application through the link below, using your log in details:</p>
            <br/>
            <a href="https://app.ez2view.info" class="text-blue-600 hover:underline"><h2 class="text-3xl text-center font-extrabold sm:text-3xl">app.ez2view.info</h2></a>
            <br/>
            <p>You can <router-link to="/getting-started/activation" class="text-blue-600 hover:underline">sign in using your gr-ID</router-link>, or alternatively you can register a new account on the log in page.</p>
            <br/>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Access Points'
};
</script>