<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">MITC Analysis</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The MITC Analysis widget allows you to generate, filter and analyse a tabular view of constraint equations. This widget supports the reporting requirements associated with the MITC incentive scheme that is administered by the AER.  </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-3/4 rounded-m mx-auto" src="@/assets/mitcanalysis-image1.png" alt="MITC Analysis Widget">
                <br/>
                <h2 class="gr-h2">Description</h2>
                <p>Using the MITC Analysis widget you can retrieve relevant constraint equations from the database and then annotate these with the details required for MITC reporting to the AER. This data is then stored in a database for direct access via SQL, or it can easily be exported to a CSV file for further analysis in a spreadsheet. </p>
                <br/>
                <p>The toolbar at the top of the widget allows you to specify a date range, create a filter on the constraint equations shown, and gives you further options for exporting the data.</p>
                <br/>
                <img class="w-3/4 rounded-m mx-auto" src="@/assets/mitcanalysis-image3.png" alt="MITC Analysis Widget">
                <br/>
                
                <h2 class="gr-h2">Generating a new report</h2>
                <p>To generate a new MITC analysis report, you must first specify a date range in which the constraint equations were invoked. You specify this date range in the toolbar as shown in the image below.</p>
                <br/>
                <img class="w-1/5 rounded-m mx-auto" src="@/assets/mitcanalysis-image4.png" alt="MITC Analysis Widget">
                <br/>
                <p>In order to filter out the constraint equations that you do not wish to display in the table, you can then create a power or quick filter where you specify filter rules on which equations are shown.</p>
               
                <br/>
                <p>Clicking 'Refresh' in the toolbar will update the table according to the dates and filters that you have specified.</p>
                <h2 class="gr-h2">Annotating and exporting a report</h2>
                <p>Once generated, you can fill out the remaining data (or add your own notes/commments) to the table by clicking on the pen icon that appears in left side of a white (editable) cell when hovering over it. This will bring up a pop-up window where you can fill out the field.</p>
                <br/>
                <img class="w-2/5 rounded-m mx-auto" src="@/assets/mitcanalysis-image5.png" alt="MITC Analysis Widget">
                <br/>
                <p>A red cross icon will appear in the table if the input type is not valid. The tooltip that appears when hovering over this icon will give instructions on the valid data types for the field. </p>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/mitcanalysis-image2.png" alt="MITC Analysis Widget">
                <br/>
                <p>To export the report simply click on the 'Export' button in the toolbar. This will export the table as a CSV with the specifed dates and filters applied.</p>

                <m-i-t-c-database />
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import MITCDatabase from './MITCDatabase.vue';

export default {
  components: { MITCDatabase},
    title: 'MITC Analysis'
};
</script>