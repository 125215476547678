<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6 pb-6 border-b-1">
            <router-link to="/complex-market-concepts" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Complex Market Concepts</p></router-link>
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Unit Conformance</h1>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <h2 class="gr-h2">Unit Conformance in the NEM</h2>
            <p>Unit Conformance in the NEM is a critical concept that ensures the stability, reliability, and efficiency of electricity supply in the grid. Unit Conformance refers to the regulatory standards and operational guidelines <b>set forth by the AEMO</b> in the dispatch procedure that ensure each unit can reliably contribute to the electricity grid without causing disruptions or hazards. </p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">What’s the difference between Conformance and Compliance?</h3>
            <p>It’s important that readers understand the difference between Conformance and Compliance: 
                <ul class="gr-list-disc">
                    <li>they are not the same.</li>
                    <li>The differences are important. </li>
                </ul>
            Readers might like to read (and listen) through Marcelle’s explanation in the article <a href="https://wattclarity.com.au/articles/2020/12/snippet2-conformance-and-compliance-whats-the-difference/" class="text-grblue hover:underline">Conformance and compliance–what’s the difference?</a> from December 2020. 
                <br><br>
                The root of the distinction is as follows: 
             </p>
            <table class="class=mx-auto divide-y divide-gray-200 border">
                <tr>
                    <th class="px-2 py-2 border bg-gray-100 leading-2 text-grblack"></th>
                    <th class="px-2 py-2 border bg-gray-100 leading-2 text-grblack"><p class="text-lg text-center font-bold">Who?</p></th>
                    <th class="px-2 py-2 border bg-gray-100 leading-2 text-grblack"><p class="text-lg text-center font-bold">Details</p></th>
                </tr>
                <tr>
                    <td  class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">Compliance</td>
                    <td  class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">AER</td>
                    <td  class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">The  <a href="https://www.aer.gov.au/" class="text-grblue hover:underline">Australian Energy Regulator</a> (AER) has specific responsibilities for monitoring (and enforcement) of each participant’s participation in the 
                        National Electricity Market as prescribed in the <a href="https://www.aemc.gov.au/regulation/energy-rules/national-electricity-rules" class="text-grblue hover:underline">National Electricity Rules</a>.</td>
                </tr>
                <tr>
                    <td  class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">Conformance </td>
                    <td  class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">AEMO</td>
                    <td  class="px-6 py-4 border whitespace-wrap text-sm leading-5 text-gray-900">In operating the market, AEMO has developed a dispatch procedure that defines when a unit is conforming (or not) with the dispatch instructions issued by AEMO.  This process is described in the AEMO’s Dispatch Procedure: 
                        <ul class="gr-list-disc">
                            <li>SO_OP_375,</li>
                            <li>with the 3rd of June 2024 version, updated following IESS, accessible <a href="https://www.aemo.com.au/-/media/Files/Electricity/NEM/Security_and_Reliability/Power_System_Ops/Procedures/SO_OP_3705%20Dispatch.pdf" class="text-grblue hover:underline">here</a></li>
                            <li>See section 3.</li>
                        </ul><br>
                        It is Conformance (not Compliance) that is the focus of various products we provide – including in the ‘Unit Dashboard’ widget of ez2view. 
                    </td>
                </tr>
            </table><br>
            There’s also further explanation <a href="https://wattclarity.com.au/other-resources/glossary/conformance-status/" class="text-grblue hover:underline"> this WattClarity® Glossary Page about ‘Conformance Status’</a>. 
            
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl"> Conformance Data</h3>
            <p>Each DUID has a Conformance Status that is determined by the AEMO according to its dispatch procedure. 
                <ul class="gr-list-disc">
                    <li>This status changes for each dispatch interval (288 dispatch intervals in each Market Day) </li>
                    <li>It is published to that Registered Participant in its DISPATCH_UNIT_CONFORMANCE table within its EMMS</li>
                    <li>That data is marked Confidential – i.e. is not even released ‘Next Day Public’.</li>
                </ul>
                To assist in broader visibility of the Conformance Status of any unit is that the AEMO will publish Market Notices when a DUID is determined to be Non-Conforming.  However, it is important for 
                readers to note that this is not a comprehensive record, because of the following: 
            </p>
            <div class="indent">
            <h3 class="mt-6 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-1.5xl">Conformance Statuses</h3>
            <p>A unit’s Conformance Status can vary between the following states: 
                <ul class="gr-list-disc">    
                    <li>Normal</li>
                    <li>Off-Target</li>
                    <li>Not-Responding</li>
                    <li>NC-Pending</li>
                    <li>Non-Conforming</li>
                    <li>Suspended (Manually applied by the AEMO)</li>
                </ul>
                Generally speaking, a unit goes from one state to another based on how far away it was away from its dispatch target and how many intervals in a row this happened.  Though the actual process is more complex than just that. 
            </p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Conformance Obligations</h3>
            <p>The obligation that a unit needs to meet depends on different factors, including: 
                <ul class="gr-list-disc">
                    <li>the units dispatch type (fully <a href="https://wattclarity.com.au/other-resources/glossary/conformance-status/" class="text-grblue hover:underline">Scheduled</a>, 
                        <a href="https://wattclarity.com.au/other-resources/glossary/conformance-status/" class="text-grblue hover:underline">Semi-Scheduled</a>, or
                        <a href="https://wattclarity.com.au/other-resources/glossary/conformance-status/" class="text-grblue hover:underline">Non-Scheduled</a>),</li>
                    <li>for a <a href="https://wattclarity.com.au/other-resources/glossary/conformance-status/" class="text-grblue hover:underline">Semi-Scheduled</a>, the Semi-Dispatch Cap (SDC) status; and </li>
                    <li>whether the unit is part of an Aggregate Dispatch Group (AGD).</li>
                </ul>
            </p>
            </div>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Our estimation of Conformance Obligations and Status inside ez2view</h3>
            <p>To assist in providing visibility of (our estimation of) a DUID’s Conformance Obligation, and hence their Conformance Stats, we have developed this flow chart to illustrate the decision logic used by the AEMO (as described in SO_OP_3705). </p>
            <br>
            <div class="flow-chart-container">
            <a href="/img/conformance-flowchart.d4e03364.png" target="_blank">
                <img  src="@/assets/conformance-flowchart.png" alt="Constraint Sets Widget">
            </a>
        </div>
            <p>Click the image to open a larger image in a second window.</p><br>
            <p>Here at Global-Roam we have come up with six different categories to describe what a unit should be doing:
            <ul class="gr-list-disc">
                <li>Follow Target</li>
                <li>No Obligations</li>
                <li>SDC (individual)   </li>
                <li>Capped in Aggregate</li>
                <li>SDC Off</li>
                <li>Follow Aggregate Target</li>
            </ul> 
            <br>
            Within the ‘Unit Dashboard’ within ez2view: 
            <ul class="gr-list-disc">
                <li>Our implementation of the logic above is implemented for all DUIDs based on ‘Next Day Public’ data.</li>
                <li>But if the client’s copy of ez2view is pointed to an EMMS that contains real data from the AEMO in the DISPATCH_UNIT_CONFORMANCE table within that EMMS, then: </li>
                <ul>
                    <li class="list-indent">The Conformance Status is shown from that table (for that unit); whilst  </li>
                    <li class="list-indent">The Conformance Obligation is still derived as per the flowchart above. </li>
                </ul>
        </ul> 
        </p>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Unit Conformance'
};
</script>

<style>
    /* .compliance-table {
        table {
            border: 1px solid black;
            border-collapse: collapse;
            width: 100%;
        }
        th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
        }
        th {
            background-color: #f2f2f2;
        }
    } */
        .indent {
            margin-left: 5%;;
        }
        .list-indent{
            margin-left: 35px;
        }
        .list-indent2{
            margin-left: 45px;
        }
        /* li::before {
            content: '•'; 
            margin-right: 8px;
        } */
        .list-indent::before{
            content: '◦'; 
            margin-right: 8px;
        }
        .flow-chart-container{
            margin-left:10%;
            max-width: 80%;
        }
</style>
