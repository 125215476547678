<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6 pb-6 border-b-1">
            <router-link to="/complex-market-concepts" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Complex Market Concepts</p></router-link>
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Forecasting</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">Forecasting is a fundamental input into decision-making for any organisation with market exposure.  </p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <h2 class="gr-h2">Forecasting in the NEM</h2>
            <p>The AEMO continuosly publish several different forecasts attempting to predict price, demand and available generation in both the short and long term...  <p>
                        <div class="mx-auto flex flex-col">
                <div class="mx-auto">
                    <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                        <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="mx-auto divide-y divide-gray-200">
                            <thead>
                                <tr>
                                <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                                    <p class="text-lg text-center font-bold">Type</p>
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                                    <p class="text-lg text-center font-bold">Outlook</p>
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                                    <p class="text-lg text-center font-bold">Description</p>
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Odd row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                    MT PASA
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    Three years ahead
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    The Medium Term Projected Assessment of System Adequacy (MT PASA) forecast details the system adequacy and generation requirements on a weekly basis for the three-year horizon, and is published monthly. 
                                </td>
                                </tr>
                                <!-- Even row -->
                                <tr class="bg-gray-50">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                    ST PASA
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    One week ahead
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    The Short Term Projected Assessment of System Adequacy (ST PASA) forecast details the system adequacy and generation requirements on a six-hourly basis for the three-week horizon, and is published weekly.
                                </td>
                                </tr>
                                <!-- Odd row -->
                                <tr class="bg-white">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                    P30 Predispatch
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    To next trading day (4:00AM)
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    30 minute predispatch forecasts price ad demand data by region on a 30-minute basis up to the end of the current trading day (4:00AM), and is published daily.
                                </td>
                                </tr>
                                <!-- Even row -->
                                <tr class="bg-gray-50">
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                    P5 Predispatch
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    One hour ahead
                                </td>
                                <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                    30 minute predispatch forecasts price ad demand data by region to the end of the next market day (4:00AM), and is published every 5 minutes.
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Price Forecasts</h3>
            <p>From reading our pages explaining <router-link to="/complex-market-concepts/unitdispatch" class="text-blue-600 hover:underline">dispatch</router-link> and bidding, you will understand the mechanism of how the price set and the role of demand and generation. The  </p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Demand Forecasts</h3>
            <p>Lorem ipsum.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Generation Forecasts</h3>
            <p>Lorem ipsum.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Further Reading on Forecasts</h3>
            <p>Over on WattClarity, we have been working to provide detailed explainers and analysis pieces on bidding and price setting in the NEM. Below you will find links to some of our covergage on the topic:
                <ol class="pl-15 pt-2 pb-2">
                    <li>• <b><a href="http://www.wattclarity.com.au/articles/2018/08/beginners-guide-to-how-dispatch-works-in-the-nem-and-hence-how-prices-are-set/" class="text-grblue hover:underline">Beginner’s Guide to how dispatch works in the NEM, and hence how prices are set </a></b> by <b>Paul McArdle</b> </li>
                </ol>
            </p>
            <br/>
            <h2 class="mt-16 mb-6 text-4xl leading-4 font-bold tracking-tight text-grblack sm:text-4xl sm:leading-8">Analysing Forecasts in ez2view</h2>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Forecast Convergence Widget</h3>
            <br/>
            <img class="mx-auto w-1/3" src="@/assets/constraints-image1.png" alt="Bound Constraints Widget">
            <br/>
            <p>Lorem ipsum.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Price Setter Data Widget</h3>
            <br/>
            <img class="mx-auto w-1/2" src="@/assets/constraints-image2.png" alt="Constraint Equations Widget">
            <br/>
            <p>Lorem ipsum. </p>
            <br/>
            <br/>
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">References</h3>
            <ol class="pl-15 pt-2 pb-2">
                <li><sup>1</sup> <a href="https://aemo.com.au/en/energy-systems/electricity/national-electricity-market-nem/system-operations/congestion-information-resource/constraint-faq" class="text-grblue hover:underline">Example Reference</a></li>
            </ol>
            

        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Forecasts'
};
</script>