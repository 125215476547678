<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Market Notices</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Market Notices widget provides access to official notices issued by the AEMO to market participants. The notices are published in real time by the AEMO and are used to provide relevant information to participants of events that impact the market.  </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/marketnotices-image1.png" alt="Market Notices Widget">
                <br/>
                <h2 class="gr-h2">Description</h2>
                <p>The Market Notices widget provides access to the market notices issued by the AEMO for the history of the NEM. The purpose of market notices is to inform participants of events and conditions that may have an impact on the operation of participants in the market - as such, some notices are autogenerated, and some are manually written and/or triggered by AEMO staff. </p>
                <br/>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/marketnotices-image5.png" alt="Market Notices Widget">
                <br/>
                <h2 class="gr-h2">Toolbar</h2>
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/marketnotices-image2.png" alt="Market Notices Widget - Search Criteria">
                <br/>
                <p>The top bar within the widget gives you the ability to filter results based on keywords that match on the Type ID or External Reference data in the notice. You can also specify length of time to be included in your search.</p>
                <h2 class="gr-h2">Table of Notices</h2>
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/marketnotices-image3.png" alt="Market Notices Widget - Search Criteria">
                <br/>
                <p>The table in the middle of the widget will display a list of all market notices that match the criteria specified in the filter. 
                   The header of the table can be right-clicked to bring up options to sort or group notices.</p>
                <h2 class="gr-h2">Notice Content</h2>
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/marketnotices-image4.png" alt="Market Notices Widget - Search Criteria">
                <br/>
                <p>The bottom panel will display the actual content of the selected notice. Each notice contains: an ID Number, a Notice Type, a Notice Description, Issue Date, External Reference and Notice Content generated by the AEMO.</p>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Important things to note about this widget </h3>
                <ul class="pl-8 pt-2 pb-2">
                    <li>• You can also use our <router-link to="/widget-guides/v8.0/notifications" class="inline text-blue-600 hover:underline">Notifications widget</router-link> to set up alerts that are triggered when the AEMO publishes a notice that meets your defined rules - e.g. Alert when a notice with the keyword "LOR" is published by the AEMO.  </li>
                </ul>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Market Notices'
};
</script>