<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg mx-auto mb-6">
        <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
        <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">SA Price Risk Forecast</h1>
        <p class="text-xl text-gray-500 leading-8 font-semibold">
          The widget presents a data table that collates contextual statistics on the energy price outlook in SA.
        </p>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
          <br />
          <img class="w-2/3 rounded-m mx-auto" src="@/assets/sapriceriskforecast.png" alt="Image of the SA price risk forecast" />
          <br />
          <h2 class="gr-h2">Description</h2>
          <p>
            This widget was developed in ez2view, designed in response to a specific client's need to assess and understand the likelihood of high prices eventuating in the coming hours ahead. The
            variables included in the table were determined based on analysis and domain expertise.
          </p>
          <p />
          <br />
          <p>
            Analysis results suggested that, while no sole variable provides an accurate indication of future high price events, operational energy use decisions which relate to wholesale price risk
            can be informed by assessing all 8 variables together.
          </p>
          <br />
          <p>Further detail on each variable in the widget is included in the table below.</p>
          <div class="mx-auto flex flex-col">
            <div class="mx-auto">
              <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table>
                    <tbody class="mx-auto divide-y divide-gray-200">
                      <tr>
                        <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                          <p class="text-lg text-center font-bold">Variable</p>
                          <p />
                        </th>
                        <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                          <p class="text-lg text-center font-bold">Description</p>
                          <p />
                        </th>
                      </tr>

                      <tr>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Estimated Trading Price</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          In the near term (where 5-minute predispatch price forecasts and dispatch price actuals are available) the trading price can be estimated. This usually provides a more
                          accurate estimate than the 30-minute forecast as it incorporates more recent market information.
                        </td>
                      </tr>
                      <tr>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Current P30</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">The forecast (30-minute predispatch) price for the trading interval.</td>
                      </tr>
                      <tr>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Max Diff</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          Max Diff captures whether there has been volatility in recent prior forecast runs for the interval. A large Max Diff indicates the price forecast for the interval has changed
                          by a large amount recently.
                        </td>
                      </tr>
                      <tr>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Std Dev</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          The Std Dev summarises the recent prior price forecasts for the interval by their standard deviation. This indicates whether recent forecast runs exhibited a wide amount of
                          variation and can indicate uncertainty in the forecast prices.
                        </td>
                      </tr>
                      <tr>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Spread</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          The Spread variable makes use of two AEMO price sensitivity runs to assess whether the future prices are sensitive to changes in demand. Higher spreads suggest that changes
                          in demand (from what is currently forecast) are projected to lead to changes in price.
                        </td>
                      </tr>
                      <tr>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Surplus</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          <p>
                            The table presents two measures of Surplus (surplus available generation). Both definitions are based on the difference between available generation and demand with the key
                            difference being how interconnector capabilities are accounted-for. Low surplus values indicate supply is tight and therefore lend support to expectations of high prices.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Wind & Large Solar</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          Wind and large solar power generation makes up a considerable proportion of energy supplied in SA. When this group of generators reduce output the balance needs come from
                          other sources which may push prices higher. Similarly, generation variability or relatively fast increases can also affect the generation mix leading to changes in price.
                        </td>
                      </tr>
                      <tr>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">IRPM</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          The instantaneous reserve plant margin. A non-price indicator of the balance between supply and demand. Calculated as available surplus generation divided by demand, it
                          presents surplus relative to the demand level. The relative value is useful for regions like SA where demand ranges from around 500 MW to over 3000 MW.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: 'SA Price Risk Forecast'
};
</script>
