<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/getting-started" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Application Messages</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Application Messages widget has a basic interface that contains messages directly from our development team.  </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/applicationmessages-image1.png" alt="Market Notices Widget">
                <br/>
                <h2 class="gr-h2">Description</h2>
                <p>Occasionally our development team will use this widget to let you know about any updates or release information about your copy of ez2view. Messages that may appear here include software release notes and other general notices about the software. </p>
                <br/>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Application Messages'
};
</script>