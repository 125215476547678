<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Constraint Equation Details</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Constraint Equation Details view shows a chart and further details about the selected Constraint Equation. 
                This widget is useful to analyse constraint equations and to observe when constraints are invoked and bound. </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/constraintequationdetails-image1.png" alt="Constraint Equation Details Widget">
                <br/>
                <h2 class="gr-h2">Description</h2>
                <p>The screen shows both current dispatch and pre-dispatch information related to the invocation of the constraint as well as more general constraint equation details.  </p>
                <br/>
                <p>The top panel of this widget contains the current invocation data for the constraint with a chart showing the LHS and RHS values for 
                    the previous 6 hours of dispatch and the remaining period for which predispatch data is available (trading day).  
                    As is common throughout the application, this will show the data from the 5-minute predispatch where available and then 30-minute pre-dispatch data once the 5 minute data is exhausted.</p>
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/constraintequationdetails-image2.png" alt="Constraint Equation Details Widget">
                <br/>
                <h2 class="gr-h2">Toolbar</h2>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/constraintequationdetails-image3.png" alt="Constraint Equation Details Widget">
                <br/>
                <p>The toolbar displays the Constraint Equation ID in bold text. There is a search button next to the ID, clicking on this button allows you to search through a list of all constraint equations, and change which equation the widget is displaying data for. </p>
                <br/>
                <p>There are two tabs in the toolbar which allows you to switch between the default 'Overview' or a 'Plain English Translation' of the constraint.</p>
                <h2 class="gr-h2">Summary Stats</h2>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/constraintequationdetails-image4.png" alt="Constraint Equation Details Widget">
                <br/>
                <p>The following summary information is shown in the top-right corner of the widget:</p>
                <div class="mx-auto flex flex-col">
                <div class="mx-auto">
                    <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                        <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="mx-auto divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                    <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                                        <p class="text-lg text-center font-bold">Field</p>
                                    </th>
                                    <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                                        <p class="text-lg text-center font-bold">Description</p>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        LHS ≤ RHS
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Left Hand Side, Operator and Right Hand Side Values of the Constraint Equation
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        MV
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Marginal Value for this Dispatch Interval
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        VD
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Violation Degree
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Headroom
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Difference between the LHS and RHS of the Constraint Equation
                                    </td>
                                    </tr>
                                    <tr class="bg-white">
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                                        Max MV
                                    </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                                        Maximum Marginal Value in Pre-Dispatch
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
                <h2 class="gr-h2">Constraint Equation Values Chart</h2>
                <br/>
                <img class="w-3/4 rounded-m mx-auto" src="@/assets/constraintequationdetails-image5.png" alt="Constraint Equation Details Widget">
                <br/>
                <p>The chart displays a time-series line graph of the LHS and RHS values for the equation. The chart looks back over the previous six hours, and looks forward at all available pre-dispatch data. The dotted vertical line represents the current dispatch interval, while the dashed vertical line represents the point in time where P30 data is available. </p>
                <br />
                <p> The constraint can be seen to be binding when the LHS equals the RHS. It's impact will be evident in the marginal value. </p>
                <br/>
                <p>You can switch the chart to display the marginal value for the constraint equation by selecting this option in the top drop-down menu. </p>
                <br/>
                <img class="w-1/6 rounded-m mx-auto" src="@/assets/constraintequationdetails-image10.png" alt="Constraint Equation Details Widget">
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Hover</h3>
                <br/>
                <img class="w-1/5 rounded-m mx-auto" src="@/assets/constraintequationdetails-image9.png" alt="Constraint Equation Details Widget">
                <br/>
                <p>Hovering over the chart with your mouse will display a tooltip that shows the RHS and LHS values, along with the dispatch interval where your mouse is positioned on the chart.</p>
                <h2 class="gr-h2">Constraint Equation Information</h2>
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/constraintequationdetails-image6.png" alt="Constraint Equation Details Widget">
                <br/>
                <p>This section of the widget shows further information about the constraint equation as published by the AEMO. This includes a brief description, submitted constraint reasons, limit type, etc.</p>
                <h2 class="gr-h2">Constraint Equation LHS Terms Table</h2>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/constraintequationdetails-image8.png" alt="Constraint Equation Details Widget - LHS Terms Table">
                <br/>
                <p>This table lists the full terms of the LHS of the equation. 
                    This table contains the Factor (multiplier), Id (DUID), Term (Unit Name) and Bid Type (Commodity Market) for each term.</p>
                <h2 class="gr-h2">Plain English Translation</h2>
                <br/>
                <img class="w-3/4 rounded-m mx-auto" src="@/assets/constraintequationdetails-image7.png" alt="Constraint Equation Details Widget">
                <br/>
                <p>With each constraint equation, the AEMO publishes a 'Plain English Translation' file that list the full terms of the equation. 
                    You can access this information by selecting the 'Plain English Translation' tab in the top toolbar. </p> <br/>
                <p> The Plain English Translation converts the IDs used on the LHS and RHS of the constraint equation to a description 
                    and reverse polish notations (RPN) used on the RHS are converted into conventional algebraic form. </p><br/>
                <p> For example the SPD ID “Q_DIRLK_H31MDNR_TRFMR” is converted to "MW flow on Molendinar 275/110kV transformer" </p>
                <br/>
                <p> Further information on constraints is available in the Constraints page of our 
                <router-link to="/complex-market-concepts" class="inline text-blue-600 hover:underline">Complex Market Concepts</router-link>
                   section of this site.
                </p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Constraint Equation Details'
};
</script>