<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg mx-auto mb-6">
        <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
        <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Interconnector Residue Trend</h1>
        <p class="text-xl text-gray-500 leading-8 font-semibold">
          There is one of these trend widgets per interconnector and they have been designed to provide some visibility of the current actuals and AEMO forecasts of interconnector flows and related energy differences on either side of an interconnector. 
        </p>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
          <br />
          <img class="w-2/3 rounded-m mx-auto" src="@/assets/interconnectorresiduetrend.png" alt="Image of the interconnector redidue trend" />
          <br />
          <h2 class="gr-h2">Description</h2>
          <p>The chart shows  trading interval data (actual and forecast) for the power flow on the interconnector. 
              Energy price differences between the two regions are also shown.  </p> <br/>
          <p> The residue is the difference in the value of the energy between the two regions, calculated from the flow across the intereconnector and the price difference between the two regions.  </p>
          <br />
          <p>When using this trend:</p>
          <ul class="pl-8 pt-2 pb-2">
            <li>• Use the mouse to hover over the chart legend (below the chart) to easily identify which lines or bars relate to which commodity. </li>
            <li>• The chart can be zoomed by dragging a mouse click horizontally across the time range of interest. </li>
          </ul>
          <br />
          <p>This chart is powered by Trends. Trends is the same underlying technology that is used to support ez2view customers with the Trend Editor).</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: 'Interconnector Residue Trend'
};
</script>
