<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Interconnector Details</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Interconnector Details widget displays registration details about an interconnector along with information about recent and forecasted flow and limits. This widget helps you understand the import/export flows between regions as well as see the constraints affecting each interconnector.  </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/interconnectordetails-image1.png" alt="Gen Change Widget">
                <br/>
                <h2 class="gr-h2">Description</h2>
                <p>This widget displays charts and tabular data about the current state of a chosen interconnector - such as current flow, limits and constraint information for the current dispatch interval. </p>
                <br/>
                <p>At the top of the widget you will see the ID of the interconnector for which you are viewing data for. Beneath this there are four main panels within the widget which are labelled in the image below and further explained in more detail.
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/interconnectordetails-image2.png" alt="Gen Change Widget">
                <br/>
                <h2 class="gr-h2">Interconnector ID</h2>
                <br/>
                <img class="w-1/6 rounded-m mx-auto" src="@/assets/interconnectordetails-image3.png" alt="Gen Change Widget">
                <br/>
                <p>The top-left corner of the widget displays the ID for the chosen interconnector. You can click on the small search button next to the ID to change the interconnector that data is being displayed for in the widget.</p>
                <h2 class="gr-h2">Interconnector Flow and Limits Chart</h2>
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/interconnectordetails-image4.png" alt="Gen Change Widget">
                <br/>
                <p>This chart is a time-series area and line graph that shows the flow, import limit and export limits for the interconnector. The dotted vertical line represents the current point in time. The x-axis of the chart shows actual data for the previous 6 hours and shows forecast data for the remaining period for which predispatch data is available.  As is common throughout the application, this will show the data from the 5-minute predispatch where available and then 30-minute pre-dispatch data once the 5 minute data is exhausted. </p> 
                <br/> 
                <p>Hovering the mouse on the chart will show the values for flow, import limit and export limit at the point in time the mouse cursor is over.</p>
                <br/>
                <img class="w-1/6 rounded-m mx-auto" src="@/assets/interconnectordetails-image8.png" alt="Gen Change Widget">
                <br/>
                <h2 class="gr-h2">Interconnector Details</h2>
                <br/>
                <img class="w-1/3 rounded-m mx-auto" src="@/assets/interconnectordetails-image5.png" alt="Gen Change Widget">
                <br/>
                <p>This panel of the widget displays general information about the interconnector as published by the AEMO. This includes a description, regions connected, the export constraint equation, the import constraint equation, max capacity, etc.</p>
                <h2 class="gr-h2">Table of Bound Constraints</h2>
                <br/>
                <img class="w-2/5 rounded-m mx-auto" src="@/assets/interconnectordetails-image6.png" alt="Gen Change Widget">
                <br/>
                <p>On the right hand side of your screen you will see a table listing all of the bound constraint equations where the interconnector is a term on the LHS. Each row in this table represents a constraint equation, and the columns of the table show current information about the equation such as LHS value, RHS value, current marginal value, etc. </p>
                <h2 class="gr-h2">Constraint Equation Values Chart</h2>
                <br/>
                <img class="w-2/5 rounded-m mx-auto" src="@/assets/interconnectordetails-image7.png" alt="Gen Change Widget">
                <br/>
                <p>Selecting a row from the table of bound constraint equations above will show a time-series line graph plotting the LHS and RHS values of the selected constraint equation. The dotted vertical line represents the current point in time, while the dashed vertical line represents the point where P5 forecast data converges into P30 forecast data.</p>
                <p></p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Interconnector Details'
};
</script>