<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Gen Change</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Gen Change widget lists all the units within the NEM, ordered by absolute change in generation since the previous dispatch interval. </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-1/4 rounded-m mx-auto" src="@/assets/genchange-image1.png" alt="Gen Change Widget">
                <br/>
                <h2 class="gr-h2">Description</h2>
                <p>The widget displays a table of all units in the NEM (for which we receive SCADA generation data). 
                    Each row in the table contains the DUID of the unit, 
                    the generation in MW for the unit in the current dispatch interval, 
                    and the change (delta) in generation between the previous and current dispatch interval. </p>
                <br/>
                <p>As the widget facilitates easy identification of changes in generation it is useful when investigating drivers of change in price and identifying unit trips.
                </p>
                <br/>
                <p>The table is sorted by the absolute value of the delta and the widget is resorted upon each change of the dispatch interval. 
                    Hence, the effect of this is to bring to the top of the table the units for which generation changed the most between the previous and current dispatch intervals. </p>
                <br/>
                <p>Clicking on the DUID of the unit will open the <router-link to="./Unit-Dashboard" class="inline text-blue-600 hover:underline">Unit Dashboard widget </router-link> for the selected unit.</p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Gen Change'
};
</script>