<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">NEM Prices (Deprecated)</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">
                Not what you are looking for? The help page for the current NEM Prices widget can be <router-link to="nem-prices" class="inline text-blue-600 hover:underline">found here</router-link>. </p>
                <br>
            <p> This NEM Prices widget shows an overview of the current spot prices for all nine commodity markets for all five regions of the NEM, as well as displaying the flow of all interconnectors.  </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/nemprices-depr-image1.png" alt="Gen Change Widget">
                <br/>
                <h2 class="gr-h2">Description</h2>
                <p>This web widget displays the live spot prices for energy and the eight FCAS commodity markets for each region. The regions are laid out in relative geographic position - with QLD at the top and TAS at the bottom. The MW value of interconnector flows are also displayed between each region.  </p>
                <br/>
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Spot Prices</h3>
                <p>Each large box represents a region of the NEM, with the inner smaller boxes representing the commodity spot prices in that region. The layout for the boxes is shown below:</p>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/nemprices-depr-image4.png" alt="Regional Prices">
                <br/>
                <p>Hovering over a price will show a tooltip with more information about the price in this interval, and the previous interval.</p>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/nemprices-depr-image5.png" alt="NEM prices tooltip">
                <br/>
                <p>Price movements since the last interval are indicated with an arrow; increased = up, decreased = down.</p>
                <br/>
                <img class="w-1/4 rounded-m mx-auto" src="@/assets/nemprices-depr-image6.png" alt="NEM prices tooltip">
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Interconnector Flows</h3>
                <br/>
                <img class="w-1/4 rounded-m mx-auto" src="@/assets/nemprices-depr-image3.png" alt="Interconnector Flows">
                <br/>
                <p>The interconnector icons between regions indicate the direction of flow, and the MW flow on the interconnector; a bar across this icon (like shown above) signifies that the interconnector is flowing at its limit. </p>
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Legend</h3>
                <br/>
                <img class="w-1/5 rounded-m mx-auto" src="@/assets/nemprices-depr-image2.png" alt="NEM Prices Legend">
                <br/>
                <p>The legend as shown above, displays the scale of the colouring used for each spot price. The colouring is based on historical maximums and minimums for each price and is further <router-link to="/design-principles/InBuiltAlerts" class="inline text-blue-600 hover:underline">explained here</router-link>. A direction of change icon is also displayed to alert you of different thresholds of change in price.</p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'NEM Prices Deprecated'
};
</script>