<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Regional Schematics</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">There is one Regional Schematic widget for each of the five regions of the NEM (QLD, NSW, VIC, SA and TAS). Each widget displays an overview map of all stations in the region, along with further details about interconnector flows, constraints and TNSP zonal flows.</p>
            <br/>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The regional schematic widgets are especially helpful in gaining an overview of the inputs for supply, and hence price, for the given region and dispatch interval.</p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/regionalschematics-image1.png" alt="Gen Change Widget">
                <br/>
                <h2 class="gr-h2">Description</h2>
                <p>Each regional schematic widget displays an overview of all generators in the region. The schematics include all major stations in each region, i.e. all stations that 1. regularly publish SCADA data to the AEMO, and; 2. have a registered capacity above 30MW . In addition, major dispatchable loads that are registered with the AEMO and provide SCADA data (e.g. hydro pumps, batteries, etc.) are also shown. </p>
                <br/>
                <p>The stations within the schematic are grouped into zones as defined by the local TNSP, and then laid out in approximate geographical position. Flows between each zone are displayed for ez2view users who have access to the relevant TNSP data. Interconnector flows in and out of the region are also displayed.</p> 
                <br/>
                <p>The regional schematic widgets are updated regularly to incorporate new stations as they come online. </p>
                <br/>
                <h2 class="gr-h2">Stations</h2>
                <p>Each station is represented with the following:</p>
                <br/>
                    <img class="w-1/5 rounded-m mx-auto" src="@/assets/regionalschematics-image2.png" alt="Regional Schematics - Stations">
                <br/>
                <p>The table below explains each component (from left to right).</p>
                <div class="mx-auto flex flex-col">
                    <div class="mx-auto">
                    <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                        <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table>
                            <tbody class="mx-auto divide-y divide-gray-200">
                                <tr>
                                <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                                    <p class="text-lg text-center font-bold">Image</p>
                                    <p />
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                                    <p class="text-lg text-center font-bold">Meaning</p>
                                    <p />
                                </th>
                                <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                                    <p class="text-lg text-center font-bold">Description</p>
                                    <p />
                                </th>
                                </tr>

                                <tr>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold"><img class="rounded-m mx-auto" src="@/assets/regionalschematics-image8.png" alt="Regional Schematics - Stations"></td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">Generation</td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">The actual output (MW) for all units of the station at the start of the current dispatch interval. 
                                        Note that, when in time-travel mode, the interval-end output is displayed.</td>
                                </tr>
                                <tr>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold"><img class="rounded-m mx-auto" src="@/assets/regionalschematics-image9.png" alt="Regional Schematics - Stations"></td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">Sparkline </td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">A chart showing the previous 2 hours of generation as a proportion of total registered capacity for the station.</td>
                                </tr>
                                <tr>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold"><img class="rounded-m mx-auto" src="@/assets/regionalschematics-image10.png" alt="Regional Schematics - Stations"></td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">Bid/Offer Indicator</td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">An icon will appear if any unit within the station has had its bid changed since the previous dispatch interval, 
                                        as <router-link to="/design-principles/InBuiltAlerts" class="inline text-blue-600 hover:underline">explained here</router-link>.</td>
                                </tr>
                                <tr>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold"><img class="rounded-m mx-auto" src="@/assets/regionalschematics-image11.png" alt="Regional Schematics - Stations"></td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">Station Name</td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">The display name for the station.</td>
                                </tr>
                                <tr>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold"><img class="rounded-m mx-auto" src="@/assets/regionalschematics-image12.png" alt="Regional Schematics - Stations"></td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">Unit Availability</td>
                                    <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">Each circle represents a unit of the station. Different circle icons are used to represent availability and constraints on the unit, 
                                        as <router-link to="/design-principles/InBuiltAlerts" class="inline text-blue-600 hover:underline">explained here</router-link>. </td>
                                </tr>
                            </tbody>
                            
                        </table>
                        </div>
                    </div>
                    </div>
                </div>
                <p>Hovering over the Bid/Offer Indicator will show more information about the bid change for the interval, including rebid explanation, offer date, bid bands affects and other related data (also shown in the Bid Details widget). </p>
                <p></p>
                <br/>
                    <img class="w-1/3 rounded-m mx-auto" src="@/assets/regionalschematics-image3.png" alt="Regional Schematics - Station Bidding">
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Loads</h3>
                <br/>
                    <img class="w-1/5 rounded-m mx-auto" src="@/assets/regionalschematics-image4.png" alt="Regional Schematics - Loads">
                <br/>
                <p>Loads are displayed using the same conventions as used for Stations except that:</p>
                <ul class="pl-16 pt-2 pb-2 list-disc">
                    <li>"(L)" is used as a suffix on the name of the unit to indicate a Load. </li>
                    <li>Load values will appear as negative numbers. </li>
                    <li>The sparkline for loads is oriented with the top of the chart representing 0 MW and load charted downwards from the top (as opposed to generation which charts up from the bottom). </li>
                </ul>
                <h2 class="gr-h2">TNSP Zones</h2>
                <p>Stations and loads are grouped into zones that are defined by the TNSP for the region - in general these zones match those published by the local TNSP in their annual report. The TNSP zones are represented by the grey panels. The example below shows the 'Northeast' zone within the VIC Schematic. </p>
                <br/>
                <p>For each zone stations/loads that are sorted from top to bottom, left to right, in approximate order of registered capacity. </p>
                <br/>
                    <img class="w-1/3 rounded-m mx-auto" src="@/assets/regionalschematics-image6.png" alt="Regional Schematics - Zones">
                <br/>
                <p>With the right subscription, interregional flows between zones are shown as per the image below. The flow number represents aggregated line flow data between the zones. 
                    The <i>'TNSP Time'</i> appears in the top right hand corner of the widget, and shows the time at which the data was read by the TNSP. 
                Please note that the TNSP Time and the current dispatch interval may be different due to the different schedules by which the TNSPs take readings. </p>
                <br/>
                    <img class="w-1/8 rounded-m mx-auto" src="@/assets/regionalschematics-image7.png" alt="Regional Schematics - Zonal Flows">
                <br/>
                <p>Please note: This intra-zonal flow data is only available in Queensland (Powerlink), Victoria (AusNet Services), and South Australia (ElectraNet). 
                    This data is published exclusively by these TNSP and hence this data is not automatically included in your licence of ez2view. You can subscribe to each of these data sets separately. 
                    Please <router-link to="/contact-us" class="inline text-blue-600 hover:underline"> contact us</router-link> if you would like to arrange to purchase a subscription to one or more of the TNSP data sets. </p>
                <h2 class="gr-h2">Interconnectors</h2>
                <br/>
                    <img class="w-1/5 rounded-m mx-auto" src="@/assets/regionalschematics-image5.png" alt="Regional Schematics - Interconnectors">
                <br/>
                <p>Each Interconnector graphic (as shown above) contains the following details: </p>
                <ul class="pl-16 pt-2 pb-2 list-disc">
                    <li><b>Interconnector Name</b> - The ID of the interconnector (as defined by the AEMO) is shown at the top.</li>
                    <li><b>Flow</b> - The MW flow on the interconnector is the large number in the centre of the left box. The triangular indicator on the box shows the direction of flow, and a bar across this indicator signifies that the interconnector is flowing at its limit.  </li>
                    <li><b>Limits</b> - The smaller boxes and number on the right show the interconnector limits in MW. The triangular indicator shows the direction of the limit. Note that these limits can both be in the same direction depending on the constraints currently invoked to control the interconnector flow.  </li>
                    <li><b>Bound Constraint Indicator</b> - The Interconnector icons may be decorated with a circle containing either "+", "-" or "+/-". 
                            This <router-link to="/design-principles/InBuiltAlerts" class="inline text-blue-600 hover:underline">icon</router-link>  indicates that the Interconnector appears on the left hand side of a Constraint Equation that is bound. 
                            If you hover your mouse over the icon, a popup appears with the names of the bound Constraint Equations, and you can click on these names to navigate to the Constraint Equation Entity Browser.   </li>
                </ul>
                </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Regional Schematics'
};
</script>