<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6 pb-6 border-b-1">
            <router-link to="/complex-market-concepts" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Complex Market Concepts</p></router-link>
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">FCAS and Co-optimisation</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">Lorem ipsum.</p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <h2 class="gr-h2">Frequency Control in the NEM</h2>
            <p>Frequency control is an essential component of any large power system. In power systems, frequency refers to the oscillations of Alternating Current (AC) throughout the system. In North America, South America and parts of Asia, system frequency is typically maintained at 60Hz but in the majority of other countries, including Australia's NEM, frequency is typically maintained at 50Hz. The exact frequency at any given moment varies generally, if the frequnecy falls outside of a small range from this target frequency, safety risks begins to appear <p>
            <br/>
            <p>Due to the physics mentioned, in an large electricity grid Supply (electricity generation) and Demand (electricity consumption) must always be equalibrium. Excess demand causes frequency to fall and excess supply causes frquency to rise. Too great a change in either direction begins to create 
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">What is system frequency and why is it important?</h3>
            <p>Lorem ipsum.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">FCAS</h3>
            <p>FCAS stands for <b>F</b>requency <b>C</b>ontrol <b>A</b>ncillary <b>S</b>ervices. It is a NEM-specific term used to desrcibe </p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Inertia</h3>
            <p>Lorem ipsum.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Co-optimisation</h3>
            <p>“Co-optimisation” refers (in part) to the way that supply of energy and supply of FCAS interact at individual supplier level – often taking more or less energy output from a generator (or battery) can lead to changes in the quantities of FCAS services it is able to provide.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Further Reading on FCAS and Co-optimisation</h3>
            <p>Over on WattClarity, we have been working to provide detailed explainers and analysis pieces on bidding and price setting in the NEM. Below you will find links to some of our covergage on the topic:
                <ol class="pl-15 pt-2 pb-2">
                    <li>• <b><a href="http://www.wattclarity.com.au/articles/2017/03/lets-talk-about-fcas/" class="text-grblue hover:underline">Let's talk FCAS </a></b> by <b>Jonathon Dyson</b> </li>
                    <li>• <b><a href="http://www.wattclarity.com.au/articles/2018/03/lets-keep-talking-about-fcas/" class="text-grblue hover:underline">Let’s Keep Talking About FCAS  </a></b> by <b>Jonathon Dyson</b> </li>
                    <li>• <b><a href="https://wattclarity.com.au/articles/2020/02/dont-forget-about-fcas/" class="text-grblue hover:underline">Don’t Forget About FCAS!   </a></b> by <b>Allan O'Neil</b> </li>
                    <li>• <b><a href="http://www.wattclarity.com.au/articles/2020/11/whats-primary-frequency-response-and-why-does-it-matter-anyway/" class="text-grblue hover:underline">What’s “Primary Frequency Response” and why does it matter anyway?  </a></b> by <b>Allan O'Neil</b> </li>
                </ol>
            </p>
            <br/>
            <h2 class="mt-16 mb-6 text-4xl leading-4 font-bold tracking-tight text-grblack sm:text-4xl sm:leading-8">Analysing FCAS and Co-optimisation in ez2view</h2>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Bids & Offers Widget</h3>
            <br/>
            <img class="mx-auto w-1/3" src="@/assets/constraints-image1.png" alt="Bound Constraints Widget">
            <br/>
            <p>Lorem ipsum.</p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Price Setter Data Widget</h3>
            <br/>
            <img class="mx-auto w-1/2" src="@/assets/constraints-image2.png" alt="Constraint Equations Widget">
            <br/>
            <p>Lorem ipsum. </p>
            <br/>
            <br/>
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">References</h3>
            <ol class="pl-15 pt-2 pb-2">
                <li><sup>1</sup> <a href="https://aemo.com.au/en/energy-systems/electricity/national-electricity-market-nem/system-operations/congestion-information-resource/constraint-faq" class="text-grblue hover:underline">Example Reference</a></li>
            </ol>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'FCAS & Co-optimisation'
};
</script>