<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg mx-auto mb-6">
        <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
        <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Station Details</h1>
        <p class="text-xl text-gray-500 leading-8 font-semibold">
          The Station Details widget displays charts and tabled information containing operating and bidding data for an individual station for the current dispatch interval. Within this widget you
          can also view a Network Diagram provided by the AEMO, which shows a diagram representation of the physical transmission infrastructure in the area for which the station is situated.
        </p>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
          <br />
          <img class="w-2/3 rounded-m mx-auto" src="@/assets/stationdetails-image8.png" alt="Station Details Widget" />
          <br />
          <h2 class="gr-h2">Description</h2>
          <p>The station details widget displays aggregated operating and bidding data for the units that comprise a station.</p>
          <br />
          <p>
            The main 'Basic Details' tab of this widget is comprised of two panels. The left panel shows a chart displaying output and bidding data for the station during a length of time that you
            specify in the toolbar. The right panel is a table showing more detailed operating and bidding data for the previous, current and next dispatch interval.
          </p>
          <br />
          <p>
            It's important to note that bidding data is only published by the AEMO at the end of the trading day - 4:00AM the following morning after the calendar day. As such, bidding data does not
            appear when you open this widget in real-time mode.
          </p>
          <br />
          <p>In addition, the widget contains a second tab which allows you to view the network diagram of the physical infrastructure built around the station.</p>
          <h2 class="gr-h2">Station ID</h2>
          <br />
          <img class="w-1/5 rounded-m mx-auto" src="@/assets/stationdetails-image9.png" alt="Station Details Widget - Unit ID" />
          <br />
          <p>
            In the top-left corner of the widget you will find the Station ID along with the schedule type for the station. You can click on the search button next to the Station ID to change the unit
            for which data is being displayed.
          </p>
          <h2 class="gr-h2">Chart</h2>
          <br />
          <img class="w-1/2 rounded-m mx-auto" src="@/assets/stationdetails-image10.png" alt="Gen Change Widget" />
          <br />
          <p>The chart displays a time-series area and line graph where:</p>
          <ul class="gr-list-disc">
            <li>
              The coloured vertical bars represent the amount of availability bid for each bid band. The colours for each bid band are explained
              <router-link to="/design-principles/InBuiltAlerts" class="inline text-blue-600 hover:underline">here in more detail.</router-link>
            </li>
            <li>The solid black line represents actual output of the unit</li>
            <li>The dashed red line represents the target output of the unit</li>
            <li>The dashed grey line connecting the black and red lines represents the difference between the actual (final) output of the unit to the target for the next dispatch interval.</li>
          </ul>
          <br />
          <p>
            If you hover your mouse over the chart, you will see a blue vertical bar and two panels - one above the graph and one below. The bottom panel shows the interval that the cursor is hovered
            over while the panel above the chart displays:
          </p>
          <ul class="gr-list-disc">
            <li>The price range for the price bucket that you are hovering over, and surrounding ranges</li>
            <li>The volumes in each of the price ranges</li>
            <li>The cumulative volume at the top of each range</li>
            <li>The figures for availability, target and actual output for the selected dispatch interval.</li>
          </ul>
          <br />
          <img class="w-1/5 rounded-m mx-auto" src="@/assets/stationdetails-image11.png" alt="Gen Change Widget" />
          <br />
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Activity Bar Charts</h3>
          <p>
            Below the graph you will see three bar charts that represent semi-dispatch cap, constraint and intervention. These bar charts will be coloured for each dispatch interval where the station
            is impacted by any of these three circumstances.
          </p>
          <br />
          <img class="w-1/2 rounded-m mx-auto" src="@/assets/unitdashboard-image6.png" alt="Gen Change Widget" />
          <br />
          <div class="mx-auto flex flex-col">
            <div class="mx-auto">
              <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="mx-auto divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                          <p class="text-lg text-center font-bold">Field</p>
                        </th>
                        <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                          <p class="text-lg text-center font-bold">Description</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Odd row -->
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Semi-Dispatch Cap</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          Semi-scheduled generators receive dispatch instructions comprising of a dispatch target (in MW) and a semi-dispatch cap (either 0/1). If the semi-dispatch cap is set to 1
                          then the generator must cap their output at or below the dispatch level for the dispatch interval. If the semi-dispatch cap is not set the generator is free to generate at
                          any level. The cap could be trigger by network limitations, inter-regional limitations or bid or market related limitations.
                        </td>
                      </tr>
                      <!-- Odd row -->
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Constraint</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">The unit appears on the LHS of a bound constraint for the given dispatch interval.</td>
                      </tr>
                      <!-- Odd row -->
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Intervention</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          The AEMO is required to intervene in the market, thus altering the market outcome, to ensure the security and reliability of the power system. The Intervention activity bar
                          indicates when the AEMO has intervened.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <h2 class="gr-h2">Table</h2>
          <br />
          <img class="w-1/3 rounded-m mx-auto" src="@/assets/stationdetails-image5.png" alt="Gen Change Widget" />
          <br />
          <p>
            The right panel of the widget displays a table showing operating and bidding data for the previous, current and next dispatch interval. The current dispatch interval is highlighted in
            light blue.
          </p>
          <br />
          <p>
            Within this widget we use left and right text alignment to signify if the data is related to the beginning or end of the dispatch interval, respectively. The table below describes the
            fields within the table of the widget:
          </p>
          <div class="mx-auto flex flex-col">
            <div class="mx-auto">
              <div class="mx-auto px-8 py-8 align-middle inline-block sm:px-8 lg:px-8">
                <div class="mx-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="mx-auto divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th class="px-2 py-2 bg-gray-100 leading-2 text-grblack">
                          <p class="text-lg text-center font-bold">Field</p>
                        </th>
                        <th class="px-2 py-2 bg-gray-100 leading-4 text-grblack">
                          <p class="text-lg text-center font-bold">Description</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Intervention</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">Marked as yes if the AEMO has sent out an intervention notice for the given dispatch interval.</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">
                          Region Price
                          <br />
                          (e.g. QLD1 Price)
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">5 minute dispatch price for the region of the station.</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Physical Run Price</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">Region price during intervention (if applicable).</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">CPD Price</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          Connection Point Dispatch Price. Unique to each generator and reflects both the physical dispatching price at the Regional Reference Node (which is different under
                          Intervention) and an adjustment that is dependent on constraints bound and affecting dispatch of units.
                        </td>
                      </tr>
                      <tr class="bg-white"></tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Is Constrained</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">Marked as yes if the unit appears on the LHS of a bound constraint for the given dispatch interval.</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Bound constraints</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          All the constraints that the unit appears on the LHS of for the given dispatch interval, with a link to the constraint dashboard for that constraint.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Conformance Obligations</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          Please refer to <a class="text-grblue hover:underline" href="../../complex-market-concepts/Conformance">this page about <b>Unit Conformance</b></a> (both obligations and
                          status).<br /><br />
                          As part of the IESS Rule Change (specifically Aggregate Dispatch Conformance) this has become more complex for certain units, so:
                          <ul class="gr-list-disc">
                            <li>This widget has been upgraded; and</li>
                            <li>This page has been updated.</li>
                          </ul>
                          With respect to this widget:
                          <ul class="gr-list-disc">
                            <li>The logic accounts for both:</li>
                            <ul ul class="gr-list-disc">
                              <li class="list-indent2">Aggregate Conformance (if applicable) and</li>
                              <li class="list-indent2">Semi-Dispatch Cap (if applicable).</li>
                            </ul>
                          </ul>
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Conformance Statuses</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          Please refer to <a class="text-grblue hover:underline" href="../../complex-market-concepts/Conformance">this page about <b>Unit Conformance</b></a> (both obligations and
                          status).<br /><br />
                          'Conformance Status' is a six-tier status index set out by the AEMO and applies to Scheduled and Semi-Scheduled units. <br /><br />
                          Data shown here:
                          <ul class="gr-list-disc">
                            <li>If you have Public Data:</li>
                            <ul>
                              <li class="list-indent">Can’t be estimated, in Real Time</li>
                              <li class="list-indent">Is estimated for ‘yesterday’ with ‘Next Day Public’ data.</li>
                            </ul>
                            <li>Tooltip will reference if the conformance status is estimated or found from a private database.</li>
                            <li>If you have Private Data, comes from DISPATCH_UNIT_CONFORMANCE table</li>
                          </ul>
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Initial</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">The combined output at the start of the interval (same as actual for the previous dispatch interval).</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Target</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">The combined set target output for the end of the dispatch interval.</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Actual</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">The combined output at the end of the interval.</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Dispatch Error</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          The deviation of the unit's output from what the AEMO expected it to be. Applies to Scheduled and Semi-Scheduled units.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Capacity</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">The Maximum/Minimum Capacity of the units used for bid validation.</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Available</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">The availability of the units for the given dispatch interval.</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Unavailable</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          The MW amount unavailable of the unit for the given dispatch interval. Calculated at Min/Max Capacity - Available.
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Offer Date</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">The submission timestamp of the bid in effect for the given dispatch interval.</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Reason</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">Bid or rebid reason as submitted by the participant (or their auto-bidding software) to the AEMO.</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Age</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">Age (in number of dispatch intervals) that the bid has been in effect for.</td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-grblack font-bold">Bid Prices</td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                          The rows represent the ten bid bands of the bid in effect. And the columns represent the MW amount allocated in each band for the given dispatch interval. A grey bar in the
                          background of these cells represents the amount dispatched of the amount allocated within the bid band.
                          <br />
                          <br />
                          <img class="w-1/2 rounded-m mx-auto" src="@/assets/unitdashboard-image18.png" alt="Unit Dashboard Bids" />
                          <br />
                          The coloured squares shown here are the same as what is shown in main chart within this widget.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <h2 class="gr-h2">Network Diagram</h2>
          <br />
          <img class="w-2/3 rounded-m mx-auto" src="@/assets/stationdetails-image7.png" alt="Station Details Widget" />
          <br />
          <p>
            The tabs section near the top of the widget will allow you to navigate to the 'Network Diagram' screen within the widget. This shows a main system diagram (as published by the AEMO) of the
            transmission network in the local area that the station is contained in.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: 'Station Details'
};
</script>
