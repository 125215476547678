<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg mx-auto mb-6">
        <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
        <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Congestion Map Prototype</h1>
        <strong class="text-xl text-gray-500 leading-8 font-semibold">
          This is a prototype of a new widget that we have constructed in order that clients can help us explore this additional way of understanding congestion.  We'd very much like your feedback.
        </strong>  
          <h2 class="gr-h2">
            It's a prototype
          </h2>
          <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <p>
              Worth emphasizing that this widget, at present, is <span class="text-black font-bold " style ="background-color:#FFFF00">a prototype</span> (and deliberately so).  It's been prepared in sufficient detail, at this point, for two purposes:
            </p>
            <ul class="gr-list-disc">
              <li>
                Such that ez2view users can gain value from the widget (even as it currently exists) in some market circumstances;
              </li>
              <li>
                But also, it's been put together via an accelerated development process to enable us to gain user input as quickly as possible.
              </li>
            </ul>
            <p>
              Being a prototype:
            </p>
            <ul class="gr-list-disc">
              <li>
                there are 'duct tape and cable ties' deployed behind the scenes, so may not perform its best in all circumstances; and
              </li>
              <li>
                we guarantee it will evolve over time (including based on customer feedback).
              </li>
            </ul>
          </div>
          <h2 class="gr-h2">
            To assist in understanding Congestion
          </h2>
          <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <p>
              There are already several widgets in ez2view that are provided to users such that they can understand congestion.  These include:
            </p>
            <ul class="gr-list-disc">
              <li>
                The <a class="gr-text-link" href="https://help.ez2viewaustralia.info/widget-guides/9.9/constraint-dashboard-post-iess">'Constraint Dashboard' widget</a> ' which was also <a class="gr-text-link" href="https://timeline.ez2viewaustralia.info/2024/09/26sept-constraintdashboard-v9-9-5-41/">upgraded after IESS</a>
              </li>            
              <li>
                The <a class="gr-text-link" href="https://help.ez2viewaustralia.info/widget-guides/9.9/unit-dashboard">'Unit Dashboard' widget</a> ' which was <a class="gr-text-link" href="https://timeline.ez2viewaustralia.info/2024/07/30july-unitdashboard-enhancements/">upgraded after IESS</a>
              </li>
              <li>
                The <a class="gr-text-link" href="https://help.ez2viewaustralia.info/widget-guides/9.9/notifications">'Notifications' widget</a> </li>
              <li>
                The <a class="gr-text-link" href="https://help.ez2viewaustralia.info/widget-guides/9.9/regional-schematics">'Regional Schematics' widget</a></li>
              <li>
                The <a class="gr-text-link" href="https://help.ez2viewaustralia.info/widget-guides/9.9/forecast-convergence">'Forecast Convergence' widget</a> ' now <a class="gr-text-link" href="https://timeline.ez2viewaustralia.info/2024/09/v9-9-5-release-notes/">upgraded to facilitate Headroom, LHS and RHS</a></li>
              <li>
                The <a class="gr-text-link" href="https://help.ez2viewaustralia.info/widget-guides/9.9/constraint-sets">'Constraint Sets' widget</a>
              </li>
              <li>
                The <a class="gr-text-link" href="https://help.ez2viewaustralia.info/widget-guides/9.9/constraint-equations">'Constraint Equations' widget</a>
              </li>
              <li>
                And more...
              </li>
            </ul>  
            <p>
              As such, this widget is intended to augment and enhance the existing capability. 
            </p>
            <p>
              No single widget can be expected to answer all the questions you have … but it should help with some of them:
            </p>
            <ul class="gr-list-disc">
              <li>
                We'd like you to let us know what circumstances it's particularly helpful in?              
              </li>
              <li>
                We'd like you to let us know what circumstances you don't find it very helpful in?
              </li>            
            </ul>
            </div>
          <h2 class="gr-h2">What does the widget currently show?</h2>
          <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <p>
              Understanding that the design in this prototype is guaranteed to evolve, what we have chosen to start with is a fairly simple concept:
            </p>
            <ul class="gr-list-disc">
              <li>
                We're showing a geographical map that you have some ability to navigate around.
              </li>
              <li>
                We're overlaying connection points … and hence DUIDs as circular markers on the map.
              </li>
              <li>
                We're colouring the DUIDs markers by two aspects of congestion:
                <ul class="gr-list-disc">
                  <li>
                    The <a class="gr-text-link" href="https://wattclarity.com.au/other-resources/glossary/cpd-price/" target="_blank">CPD Price</a> ' which is also shown in:
                    <ul>
                      <li>
                        The <a class="gr-text-link" href="https://help.ez2viewaustralia.info/widget-guides/9.9/constraint-dashboard-post-iess">'Constraint Dashboard' widget</a>
                      </li>
                      <li>
                        The <a class="gr-text-link" href="https://help.ez2viewaustralia.info/widget-guides/9.9/unit-dashboard">'Unit Dashboard' widget</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    The Local Price Adjustment:
                    <ul>
                      <li>
                        Which is one input into the <a class="gr-text-link" href="https://wattclarity.com.au/other-resources/glossary/cpd-price/" target="_blank">CPD Price</a>; but
                      </li>
                      <li>
                        Which is not shown (directly) in any other widget, at this point.
                      </li>                    
                    </ul>
                  </li>
                </ul>              
              </li>
              <li>
                This widget shows data for a single point in time (the 'now' point of the window)
              </li>
              <li>
                Limited tool tip capability is supplied at this time. Hover over a circle to reveal it's DUID. Click to show relevant data in a popup.
              </li>
            </ul>
            <!--
            <img class="w-3/4 rounded-m mx-auto" src="@/assets/congestionmapprototype-image1.png" alt="Congestion map prototype" />
            -->
          </div>
          <h2 class="gr-h2">We are keenly seeking your input!</h2>
          <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <p>
              Finally, let's reiterate that we are keenly seeking your input (especially) at this point, as we take our early steps in building this widget.
            </p>
            <p>
              To contact us:
            </p>
            <ul class="gr-list-disc">
              <li>Remember the 'feedback' button (top right of the widget) can help in providing us context about what you are looking at.</li>
              <li>But you could, of course, email directly or call.</li>
            </ul>
            <p>We look forward to hearing from you!</p>
          </div>
         </div>
      </div>
    </div>
</template>

<script>
export default {
  title: 'Congestion Map'
};
</script>
