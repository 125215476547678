<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Constraint Equations</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The Constraint Equations widget shows all constraint equations that have recently been invoked, are invoked now, or are scheduled to be invoked in predispatch. This widget allows you to filter and watch constraint equations so that you can keep an eye on constraints that affect your organisation. </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/constraintequations-image1.png" alt="Gen Change Widget">
                <br/>
                <h2 class="gr-h2">Description</h2>
                <p>The widget is divided into two areas - the top panel is dynamically populated showing all constraint equations that match the selection criteria in the toolbar. The lower panel of the widget is populated with a fixed set of constraint equations ('Watched Constraints') that you can specifically select. </p>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/constraintequations-image8.png" alt="Gen Change Widget">
                <br/>
                <h2 class="gr-h2">Invoked Constraint Equations Charts</h2>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/constraintequations-image5.png" alt="Gen Change Widget">
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Toolbar</h3>
                <p>The constraints that are displayed as charts in the upper panel are selected by ez2view according to criteria that you provide. There are two aspects of the upper panel that you can control - the first is a Constraint Equations Filter that you can use to select which constraint equations are relevant to you, and the second is the order in which the constraint equations are displayed on the screen. </p>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Filter</h3>
                <br/>
                <img class="w-1/4 rounded-m mx-auto" src="@/assets/constraintsets-image2.png" alt="Gen Change Widget">
                <br/>
                <p>You can filter the constraint equations that appear on the Constraint Equation Screen so that ez2view displays the equations that are most relevant to you. From the "Filters" drop down you can select a filter to apply to the upper panel. </p>
                <br/>
                <img class="w-2/5 rounded-m mx-auto" src="@/assets/constraintsets-image3.png" alt="Gen Change Widget">
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Ordering</h3>
                <p>ez2view orders the display of the constraint equations in the upper panel from left to right, and then top to bottom. You can control the order in which ez2view displays constraint equations in the upper panel using the "Order By" drop down. By default, ez2view uses the absolute value of the current marginal value to perform this ordering. If you select "Maximum MV", ez2view will use the absolute value of the Maximum Marginal Value to perform this ordering. If you are using Maximum Marginal Value, then you can also select a time period that will control how far into the predispatch data ez2view looks to determine the Maximum Marginal Value. </p>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Display Layout</h3>
                <p>You can control the number of rows and columns that ez2view displays, as well as the ratio of rows between the upper and lower panel. Click the cog icon at the top right of the screen (next to the Help button) to invoke the Constraint View Settings dialog. </p>
                <br/>
                <img class="w-1/4 rounded-m mx-auto" src="@/assets/constraintequations-image6.png" alt="Gen Change Widget">
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Equation Sparklines</h3>
                <br/>
                <img class="w-1/6 rounded-m mx-auto" src="@/assets/constraintequations-image2.png" alt="Gen Change Widget">
                <br/>
                <p>Each Constraint Equation is represented by a sparkline where the solid black line represents the RHS value of the equation and the dashed grey line represents the LHS value.</p>
                <br/>
                <p>The vertical line on the chart represents the current dispatch interval - to the left of this is dispatch history, and to the right predispatch data (five minute predispatch data, followed by thirty minute predispatch data when the five minute data is exhausted). </p>
                <br/>
                <img class="w-2/5 rounded-m mx-auto" src="@/assets/constraintequations-image7.png" alt="Gen Change Widget">
                <br/>
                <h2 class="gr-h2">Watched Constraints</h2>
                <br/>
                <img class="w-1/2 rounded-m mx-auto" src="@/assets/constraintequations-image3.png" alt="Gen Change Widget">
                <br/>
                <p>The panel at the bottom of the widget displays your 'Watched Contraints'. To add a constraint equation to this panel you can simply type in the constraint equation Id in the text field and then click the 'Add' button.</p>
                <br/>
                <p>Alternatively, you can click on the button in the top-right corner of a chart to add it to this panel like shown below. </p>
                <br/>
                <img class="w-1/5 rounded-m mx-auto" src="@/assets/constraintequations-image4.png" alt="Gen Change Widget">
                <br/>

            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Constraint Equations'
};
</script>