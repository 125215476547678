<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6">
            <router-link to="/widget-guides" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Widget Guides</p></router-link>
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Configured Trends</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">The <router-link to="/widget-guides/v8.0/trend-editor" class="inline text-blue-600 hover:underline">Trend Editor</router-link> widget allows you to create a trended chart or table based on data fields that you specify. Once created and published, your trend will be viewable as it's own widget, and will work in both real-time and time travel mode just like other widgets within ez2view.  </p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/configuredtrends-image1.png" alt="Gen Change Widget">
                <br/>
                <h2 class="gr-h2">Description</h2>
                <p>This help page runs you through using and viewing your own configured trend, which you would have already created through the Trend Editor widget. The Trend Editor widget allows you to configure and publish these trends as like any other widget within ez2view, and hence they will update in real-time mode as new data becomes available, and will show point-in-time historical data if viewed in time-travel mode. </p>
                <h2 class="gr-h2">Chart</h2>
                <p>Your published chart will be in the form of a time-series line and/or area graph where the colours of each series in the chart would have been configured in the trend editor before it was published. The current point in time will be shown as a dotted vertical line on your chart.  </p>
                <br/>
                <p>If the data in your chart spans over a longer period that can be displayed, you will see slider buttons above the chart that will allow you to pan across and modify the x-axis of the chart.</p>
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/configuredtrends-image4.png" alt="Gen Change Widget">
                <br/>
                <p>Hovering over the chart will show you the specific values and the point in time of where your mouse is positioned on the chart.</p>
                <br/>
                <img class="w-1/4 rounded-m mx-auto" src="@/assets/configuredtrends-image3.png" alt="Gen Change Widget">
                <br/>
                <p>A blue button appears in the top-right corner of your chart. Clicking on this button will allow you to export the displayed data in a CSV file.</p>
                <br/>
                <img class="w-1/7 rounded-m mx-auto" src="@/assets/configuredtrends-image5.png" alt="Gen Change Widget">
                <br/>
                <h2 class="gr-h2">Table</h2>
                <p>A trend published as a table will show your specified fields as a simple data table with the columns representing intervals of time. Similar to the chart, you can also click on the blue button in the top left hand corner to export the data as a CSV file.</p>
                <br/>
                <img class="w-2/3 rounded-m mx-auto" src="@/assets/configuredtrends-image2.png" alt="Gen Change Widget">
                <br/>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Configured Trend'
};
</script>